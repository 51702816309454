@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import '~videojs-resolution-switcher/lib/videojs-resolution-switcher.css';
$primaryColor: #f15a22;
$secondaryColor: #fff4e7;

$blackColor: #303030;
$darkGreyColor: #6c6c6c;
$greyColor: #a8a8a8;
$grey2Color: #bdbdbd;

$lightGreyColor: #e4e4e4;
$whiteColor: #f8f8f8;
$redColor: #c80000;
$greenColor: #12b886;
$yellowColor: #e7ac08;
$blueColor: #38576b;

.swipeableViews ::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.bold-text {
  font-weight: 600;
}
.CalendarMonth_caption {
  select {
    border: none;
    text-decoration: underline;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding-right: 3px;
    font-weight: bold;
    font-size: 14px;
  }
}

div[role='button'].DayPickerNavigation_button {
  margin-top: 20px;
  svg.MuiSvgIcon-root {
    color: $blueColor;
    fill: $blueColor;
  }
}

input.DateInput_input {
  border-bottom: 1px solid $greyColor;
  padding-left: 0px;
}

input.DateInput_input.DateInput_input__focused {
  border-bottom: 3px solid $blueColor;
}

button.DateRangePickerInput_clearDates {
  top: 19px;
}
.CalendarDay__today {
  border: 1px solid $greyColor !important;
}

.DateRangePicker {
  .DateRangePickerInput__withBorder {
    border-radius: 4px;
  }
  .date_range_to {
    padding-right: 5px;
    padding-left: 5px;
  }
  .date-range-error {
    color: $redColor;
  }
}

.DateRangePickerInput {
  .DateInput {
    width: 110px;

    .DateInput_input {
      line-height: 15px;
    }
  }
}

input.DateInput_input {
  font-size: 15px;
}

.CalendarDay.CalendarDay__default {
  border: none;
}

.CalendarDay.CalendarDay__default:hover {
  background-color: $lightGreyColor;
}
// Will edit everything selected including everything between a range of dates
.CalendarDay.CalendarDay__selected_span:hover {
  background-color: $lightGreyColor;
  color: black;
  border: 1px solid black;
}

button[type='button'] {
  svg.icon {
    fill: $blueColor;
  }
}

html {
  scroll-behavior: smooth;
  overflow: initial;
}

input::placeholder {
  color: $greyColor !important;
}

body {
  margin: 0;
  padding: 0 !important;
  color: #5c5c5c;
  background-color: #f5f5f5;
  // line-height: 1;

  .s7t-available-list,
  .s7t-waiting-list {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
  }

  .s7t-table-row-algin-top {
    td {
      vertical-align: top !important;
    }
  }

  .s7t-text-lowercase {
    text-transform: lowercase;
  }

  .s7t-available-list {
    color: #6db33f !important;
  }

  .s7t-waiting-list {
    color: #bbbbbb !important;
  }
}

.s7t-required {
  &:before {
    content: '*';
    color: #f3575a;
    padding-right: 3px;
  }
}

.s7t-tooltip {
  background: white;
}

hr {
  border-color: #dedede;
}

.basic-single div {
  border-radius: 0px;
}

a {
  color: blue;
}

#menu-appbar {
  div {
    &:nth-child(2) {
      min-width: 200px;
    }
  }
}

#menu-dropdown-anchor {
  position: absolute;
  right: 0;
  top: 110px;
}

#child-change-dropdown-anchor {
  position: relative;
  top: 50px;
}

.s7t-cursor {
  cursor: pointer !important;
}

.s7t-grey-scale {
  filter: grayscale(100%);
  opacity: 0.5;
}

.s7t-font-16 {
  font-size: 16px !important;

  span {
    font-size: 16px !important;
  }
}

.s7t-font-24 {
  font-size: 24px !important;

  span {
    font-size: 24px !important;
  }
}

.s7t-font-28 {
  font-size: 28 !important;

  span {
    font-size: 28 !important;
  }
}

.s7t-font-32 {
  font-size: 32px !important;

  span {
    font-size: 32px !important;
  }
}

.s7t-font-10 {
  font-size: 10px !important;

  span {
    font-size: 10px !important;
  }
}

.s7t-font-14 {
  font-size: 14px !important;

  span {
    font-size: 14px !important;
  }
}

.s7t-font-12 {
  font-size: 12px !important;

  span {
    font-size: 12px !important;
  }
}

.s7t-font-18 {
  font-size: 18px !important;

  span {
    font-size: 18px !important;
  }
}

.s7t-font-italic {
  font-style: italic !important;
}

.s7t-z-index {
  position: relative;
  z-index: 1;
}

.s7t-border-radius-24px {
  border-radius: 24px !important;
}

.s7t-nowrap {
  white-space: nowrap !important;

  span {
    white-space: nowrap !important;
  }
}

.s7t-text-align {
  text-align: center !important;
}

.s7t-text-align-left {
  text-align: initial !important;
}

.s7t-table-overflow {
  width: 100%;
  overflow: auto !important;
}

.s7t-vertical-align {
  display: block;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.s7t-profile-image {
  width: 200px !important;
  height: 200px !important;

  img {
    width: 200px;
    height: 200px;
  }
}

.s7t-table-head-shadow {
  box-shadow: 0px 1px 1px #f1ebeb;
}

@media (min-width: 600px) and (max-width: 1199px) {
  .s7t-profile-image {
    width: 100px !important;
    height: 100px !important;

    img {
      width: 100px;
      height: 100px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1580px) {
  .s7t-profile-image {
    width: 150px !important;
    height: 150px !important;

    img {
      width: 150px;
      height: 150px;
    }
  }
}

.s7t-chip svg {
  color: white;
}

.s7t-padding-24 {
  padding: 0px 24px;
}

.s7t-padding-0 {
  padding: 0 !important;
}

.s7t-border-radius {
  border-radius: 3px !important;
}

.s7t-font-normal {
  font-weight: normal !important;

  span {
    font-weight: normal !important;
  }
}

.MuiChip-deleteIcon {
  color: white;
}

.s7t-common-table {
  width: calc(100% - 2px);
  margin: 0 auto;

  tbody {
    .MuiTableRow-root {
      border: 2px solid transparent;
      border-bottom: 2px solid #e0e0e0;
    }

    tr {
      &:nth-child(odd) {
        background: #ffffff;
      }

      &:nth-child(even) {
        background: #fbfbfb;
      }
    }
  }
}

.s7t-img-contain {
  object-fit: contain;
}

.s7t-img-cover {
  object-fit: cover;
}

.s7t-space-evently {
  div > div > div {
    justify-content: space-evenly !important;
  }
}

.s7t-tab-style > span > span > span {
  font-size: 16px !important;
}

@media (min-width: 1350px) and (max-width: 1499) {
  .s7t-tab-style > span > span > span {
    font-size: 16px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1349px) {
  .s7t-tab-style > span > span > span {
    font-size: 16px !important;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .s7t-tab-style > span > span > span {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 899px) {
  .s7t-tab-style > span > span > span {
    font-size: 16px !important;
  }
}

.s7t-button-tcc {
  span {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  text-transform: capitalize;
  border-radius: 20px !important;
}

.s7t-button {
  span {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  text-transform: capitalize;
  border-radius: 3px !important;
}

.s7t-overflow-break-next-line {
  overflow-wrap: break-word !important;
}

@media only screen and (max-width: 899px) {
  .s7t-button {
    span {
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    text-transform: capitalize;
    border-radius: 3px !important;
  }

  .s7t-button-tcc {
    span {
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    text-transform: capitalize;
    border-radius: 20px !important;
  }
}

.s7t-sort-icon {
  padding: 0.5px;
  object-fit: contain;
  width: fit-content;
}

.sn2-row-align-center {
  display: flex;
  justify-content: center;
}

.sn2-d-flex {
  display: flex;
}

.s7t-file-upload-container {
  .s7t-uploaded-img {
    position: relative;

    &:hover {
      .s7t-uploaded-img-hover {
        display: block !important;
        position: absolute;
        bottom: 0%;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .s7t-uploaded-img-nohover {
      display: block !important;
      position: absolute;
      bottom: 0%;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .s7t-file-upload-prompt {
    display: inline-grid;
    margin: 0px 0px 0px auto;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    width: 190px;
    height: 190px;
    box-shadow: none;
    background-color: transparent;
    text-align: center;
    border: 1px dashed #717171;
    border-radius: 0%;
  }

  .s7t-file-upload-prompt-custom {
    display: inline-grid;
    margin: 0px 0px 0px auto;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    width: 190px;
    height: 190px;
    box-shadow: none;
    background-color: #f8f8f8;
    text-align: center;
    border: 1px dashed #717171;
    border-radius: 1%;
  }

  .upload-img {
    width: '36.8px';
    height: '23.6px';
    margin-left: 'auto';
    margin-right: 'auto';
  }
}

.s7t-margin-date {
  margin-left: 7.5% !important;
}

.s7t-success-font {
  font-size: 16px !important;
  color: #717171 !important;
  font-weight: normal !important;
}

.s7t-stepper-connect span {
  border-top-width: 5px !important;
  margin: 12px !important;
  position: relative;
  top: 3px;
}

.s7t-stepper-icon span span {
  font-size: 18px;
  font-weight: 600 !important;
  color: #717171;
}

.s7t-stepper-icon span svg {
  width: unset !important;
  height: 60px !important;
}

.s7t-font-500 {
  font-weight: 500 !important;
}

.s7t-margin-16px {
  margin-top: 16px !important;
}

.s7t-margin-19px {
  margin-top: 19px !important;
}

.s7t-font-normal {
  font-weight: normal !important;
}

.s7t-center-items {
  align-items: center;
}

.s7t-rounded-corner {
  border-radius: 3px;
}

.s7t-content {
  min-height: 80vh;
  margin: 15px 30px;
  padding: 35px;

  &.s7t-with-submenu {
    position: relative;
    left: 224px;
  }
}

.s7t-header {
  height: 80px;
  position: relative;

  .s7t-title {
    position: absolute;
    display: flex;
    right: 10px;
  }
}

// .s7t-image-pos {
//     position: absolute;
// }

.s7t-body-markers {
  position: absolute;
  background: #22c6ad;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

/****** Colors *****/

.s7t-pending {
  color: #f5a623 !important;
}

.s7t-label-override {
  position: relative;
  top: 18px;
}

.s7t-white-color {
  color: #ffffff !important;

  span {
    color: #ffffff !important;
  }
}

.s7t-chip-gray-color {
  color: #9e9e9e;
}

.s7t-russian-black {
  color: #131315;
}

.s7t-approved {
  color: #6db33f !important;
}

.s7t-declined {
  color: #e86b32 !important;
}

.s7t-resigned {
  color: red !important;
}

.s7t-disabled {
  color: #7c7c7c !important;
}

.s7t-text-black {
  span {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  color: rgba(0, 0, 0, 0.87) !important;
}

.s7t-text-orange {
  span {
    color: #e7ac08 !important;
  }

  color: #e7ac08 !important;
}

.s7t-text-green-2 {
  span {
    color: $greenColor !important;
  }

  color: $greenColor !important;
}

.s7t-text-pastel {
  span {
    color: #56c4c5 !important;
  }

  color: #56c4c5 !important;
}

.s7t-text-green {
  span {
    color: #6db33f !important;
  }

  color: #6db33f !important;
}

.s7t-text-very-light-grey {
  span {
    color: #dadada !important;
  }

  color: #dadada !important;
}

.s7t-text-greyish-brown {
  span {
    color: #4a4a4a !important;
  }

  color: #4a4a4a !important;
}

.s7t-text-brownish-grey {
  span {
    color: #717171;
  }

  color: #717171 !important;
}

.s7t-text-watermelon {
  span {
    color: #f3575a !important;
  }

  color: #f3575a !important;
}

.s7t-text-reddish-orange {
  span {
    color: #f15a22 !important;
  }

  color: #f15a22 !important;
}

.s7t-text-red {
  span {
    color: red !important;
  }

  color: red !important;
}

.s7t-disabled-label {
  color: #71717178 !important;
}

.s7t-text-orange {
  span {
    color: #f5a623 !important;
  }

  color: #f5a623 !important;
}

.s7t-text-grey {
  span {
    color: #959595 !important;
  }

  color: #959595 !important;
}

.s7t-text-lightgrey {
  span {
    color: #c4c4c4 !important;
  }

  color: #c4c4c4 !important;
}

.s7t-text-darkgrey {
  span {
    color: #5e5e5e !important;
  }

  color: #5e5e5e !important;
}

.s7t-text-light-gray {
  color: #cccccc !important;

  span {
    color: #cccccc !important;
  }
}

.s7t-disabled-gray {
  color: gray;
}

.s7t-background-dimgray {
  background: #e7e7e7;
}

.s7t-enabled-gray {
  background: #717171;
}

.s7t-background-red {
  background: $redColor;
}

.s7t-background-blue {
  background: #6236ff;
}

.s7t-background-green {
  background: $greenColor;
}

.s7t-background-pink {
  background: #ffd9b5;
}

.s7t-text-gray-color {
  color: #939393 !important;
}

.s7t-text-light-green {
  color: #00c366;
}

.s7t-icon-light-green svg {
  background-color: #00c366;
  border-radius: 12px;
  color: #ffffff;
}

.s7t-text-warning-orange {
  color: #f9a453;
}

.s7t-padding-bottom-32px {
  padding-bottom: 32px;
}

/**************************************/
/********************common -border*****************/
.s7t-border-gray {
  border: solid 1px #cfcfcf !important;
}

.s7t-bold-700 {
  font-weight: 700 !important;
}

.s7t-bold-600 {
  font-weight: 600 !important;

  span {
    font-weight: 600 !important;
  }
}

.s7t-bold {
  font-weight: bold !important;

  div {
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 1px;
  }
}

.s7t-injury-tooltip {
  text-decoration: underline;
}

.s7t-input-label-18 {
  font-size: 18px !important;
  color: #717171 !important;
}

.s7t-big-font {
  font-size: 1rem;

  div {
    font-size: 1rem;
  }

  font-weight: bold;
}

.s7t-menu-item-img {
  font-size: 22px;
  margin-right: 10px;
}

.s7t-text-uppercase {
  text-transform: uppercase !important;
}

.s7t-text-capitialise {
  text-transform: capitalize !important;
}

.s7t-text-no-wrap {
  white-space: nowrap !important;
}

.s7t-text-pre-line {
  white-space: pre-line !important;
}

.s7t-text-pre-wrap {
  white-space: pre-wrap;
}

.s7t-shadow-bottom {
  box-shadow: 0px 2px 2px 0px #dedede;
  z-index: 1;
}

.s7t-shadow-top {
  box-shadow: 0px -2px 2px 0px #dedede;
  z-index: 1;
}

.s7t-margin-bottom {
  margin-bottom: 30px !important;
}

.s7t-full-width {
  width: 100% !important;
}

.s7t-full-height {
  height: 100vh;
}

.s7t-customfields input {
  font-size: 16.5px !important;
  color: #717171 !important;
}

.s7t-margin-24 {
  margin: 24px 0px !important;
}

.s7t-auto-margin {
  margin: auto !important;
}

.s7t-grey-border-left-right-bottom {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.s7t-grey-border-left-right-top {
  border-top: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.s7t-grey-border-left-right {
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.s7t-grey-border-bottom {
  border-bottom: 1px solid #dadada;
}

.s7t-grey-border-top {
  border-top: 1px solid #dadada;
}

.s7t-grey-border {
  border: 1px solid #dadada;
}

.s7t-reddish-orange-border {
  border: 1px solid #f15a22;
}

.s7t-homepage-container {
  box-shadow: 1px 1px 3px 0px grey;
  margin-top: 20px;
}

.s7t-border-5-red {
  border: solid 5px #ef5350;
}

.s7t-border-5-transparent {
  border: solid 5px transparent;
}

.s7t-error {
  color: #f3575a;
  font-size: 12px;
  display: block;
}

.s7t-note {
  color: #f3575a !important;
  font-size: 12px !important;
}

.s7t-select-icon {
  width: 17px;
  height: 17px;
  display: inline-block;
  border-radius: 50%;
}

.s7t-wait-icon {
  margin-left: 1px;
  background-color: #f5a623;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}

.s7t-waiting-btn {
  margin-left: 1px;
  background-color: #7c7c7c;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}

.s7t-table-select-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
}

.s7t-table-row-hover {
  &:hover {
    background: #d8d8d8 !important;
    cursor: pointer;
  }
}

.s7t-word-break {
  word-break: break-word;
}

.s7t-no-padding {
  padding: 0px !important;
}

.s7t-no-margin {
  margin: 0px !important;
}

.s7t-time-input {
  padding: 6px 0 7px !important;
}

.s7t-label {
  display: contents !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.s7t-autocomplete-search {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1111;
  width: auto;
  min-width: 100%;
  max-height: 300px;
  min-width: 100%;
  overflow-y: scroll;
  background: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);

  .s7t-autocomplete-search-result-li {
    overflow: hidden;
    min-width: 100%;
    max-width: 600px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    line-height: 2.5;
  }

  li:hover * {
    color: white !important;

    .dot-style {
      background-color: white !important;
    }
  }
}

.s7t-margin-top {
  margin-top: 24px !important;
}

.s7t-tabs-no-shadow {
  display: block !important;
  box-shadow: none !important;
}

.s7t-truncate-multiline {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  line-height: 1.5;
  -webkit-box-orient: vertical;
  height: 14px * 1.5 * 2;
  /* Fallback for non-webkit */
}

/*********Country Code**********/

.basic-single {
  div {
    border-style: unset !important;
    border-bottom: solid 1px #dadada !important;

    div {
      border-style: unset !important;
      border-bottom: solid 0px #dadada !important;
    }

    div {
      border-style: unset !important;
      border-bottom: solid 0px #dadada !important;
    }
  }
}

.s7t-child-profile-tab,
.s7t-vacancy-selection-tab,
.s7t-vacancy-category-tab {
  box-shadow: none !important;

  button {
    border-bottom: 1px solid #dadada;
  }
}

.s7t-center-stepper,
.s7t-registration-stepper {
  .s7t-step {
    position: relative;
    top: 20px;

    text,
    circle,
    path {
      display: none;
    }

    .s7t-step-label {
      position: relative;
      bottom: 20px;
      z-index: 0;
    }

    .s7t-visit-info {
      margin-bottom: 2em;
    }

    .s7t-process-svg-holder {
      position: relative;
      z-index: 1;
      width: 3em;
      height: 3em;
      margin: auto;
      align-items: center;
      display: flex;
      margin-top: -10px;
      border-radius: 50%;
      color: #ffffff;
      border: none;

      path {
        display: block;
      }

      &.s7t-intro-inactive-svg {
        width: 4em;
        height: 4em;

        &.s7t-intro-inactive-margin {
          margin-top: -2px !important;
        }

        &.s7t-intro-mob-inactive-margin {
          margin-left: 7px !important;
        }
      }

      &.s7t-intro-active-svg {
        width: 8em;
        height: 8em;

        &.s7t-intro-active-margin {
          margin-top: -36px !important;
        }

        &.s7t-intro-mob-active-margin {
          margin-left: -25px !important;
        }
      }
    }

    .s7t-intro-svg-holder {
      color: #fff;
      width: 5em;
      height: 5em;
      margin-top: -10px;
      display: flex;
      z-index: 1;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      background-color: #fff;
      border: none;

      &.s7t-vertical {
        margin-top: 0px !important;
      }

      path {
        display: block;
      }
    }

    .s7t-intro-connector {
      span {
        border: 0;
      }
    }

    .s7t-step-description {
      position: relative;
      left: 25px;
    }

    &.s7t-completed-step {
      .s7t-process-svg-holder {
        background: #6db33f;
      }

      .s7t-stepper-connector {
        span {
          border: 3px solid #6db33f;
        }
      }
    }

    &.s7t-completed-step-intro {
      .s7t-process-svg-holder {
        background: #6db33f;
      }

      .s7t-stepper-connector {
        span {
          border: 2px solid #6db33f;
        }
      }
    }

    &.s7t-completed-step-thankyou {
      .s7t-process-svg-holder {
        background: white;
      }

      .s7t-stepper-connected {
        span {
          border: 0px;
        }
      }
    }

    &.s7t-active-step {
      .s7t-process-svg-holder {
        border: 3px solid #6db33f;
        background: #dadada;
      }

      .s7t-stepper-connector {
        span {
          border: 3px solid #6db33f;
        }
      }
    }

    &.s7t-pending-step {
      .s7t-process-svg-holder {
        background: #dadada;
      }
    }

    &.s7t-stepper-connector {
      margin: 0px 10px 0px 10px;
    }

    &.s7t-stepper-connected {
      margin: 0px 10px 0px 10px;
    }

    &:last-of-type {
      .s7t-stepper-connector {
        margin: 0;
      }

      .s7t-stepper-connected {
        margin: 0px 10px;
        opacity: 0.4;
      }

      @media (max-width: 1000px) {
        .s7t-stepper-connected {
          margin: 0px 10px 0px 13px !important;
        }
      }

      @media (max-width: 960px) {
        .s7t-stepper-connected {
          margin: 0px 10px 0px 18px !important;
        }
      }

      @media (max-width: 930px) {
        .s7t-stepper-connected {
          margin: 0px 10px 0px 22px !important;
        }
      }

      @media (max-width: 890px) {
        .s7t-stepper-connected {
          margin: 0px 10px 0px 31px !important;
        }
      }

      @media (max-width: 860px) {
        .s7t-stepper-connected {
          margin: 0px 10px 0px 34px !important;
        }
      }

      @media (max-width: 830px) {
        .s7t-stepper-connected {
          margin: 0px 10px 0px 37px !important;
        }
      }
    }

    .s7t-stepContent {
      opacity: 0.4;
    }

    &:nth-last-child(2) {
      .s7t-stepper-connected {
        margin: 0px 10px;
        opacity: 0.4;
      }
    }
  }
}

/*** Image FullView Loader ***/
.s7t-full-image-show {
  div {
    div {
      background: rgba(0, 0, 0, 0.5) !important;
    }
  }

  .s7t-appbar {
    background: rgba(255, 255, 255, 0.5);
    position: fixed !important;
  }

  .s7t-div-overRide {
    background: none !important;

    div {
      background: none !important;

      div {
        background: none !important;
      }
    }
  }

  .s7t-image {
    object-fit: contain;
    max-width: 90%;
  }

  .s7t-imgbox {
    padding: 10%;
  }
}

/*** Loader Styles Start ***/
.s7t-loader {
  border: 6px solid #f5f5f5;
  border-top: 6px solid #ff894a;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*** ---------- ***/

.s7t-not-found-container {
  padding-top: 5%;
}

.s7t-input-common-style {
  div {
    input {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7c7c7c;
    }
  }
}

/*** Vacancy Planning ***/

.s7t-notify-change-bubble {
  width: 12px;
  height: 12px;
  position: absolute;
  display: block;
  right: 10%;
  top: 10%;
  border-radius: 50%;
}

.s7t-review-capacity-dialog {
  margin-top: 15px;
  margin-bottom: 15px;

  table {
    th,
    td {
      padding: 6px;
      text-align: center;
      width: 25px;
      padding-right: 0 !important;
    }
  }

  .s7t-vacancy-category-tab {
    box-shadow: none;

    button {
      border-bottom: 1px solid #dadada;

      &[aria-selected='false'] {
        color: #bbbbbb;
      }

      &[aria-selected='true'] {
        color: #717171;
      }
    }
  }
}

.s7t-vacancy-planning-container {
  .s7t-close-review-btn {
    position: absolute;
    right: 4em;
    margin-top: -4em;
  }

  .s7t-capacity-listing-block {
    .s7t-edit-planning-btn {
      position: absolute;
      right: 5%;
      margin-top: 25px;
    }

    .s7t-tab-curr-hist {
      button {
        width: 100%;
        max-width: 50%;
      }
    }

    .s7t-edit-disabled-info {
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      font-size: 0.9rem;
    }
  }

  .s7t-approval-block {
    table {
      th,
      td {
        padding: 9px;
        text-align: center;
        width: 140px;
      }
    }

    .s7t-approval-title {
      padding: 30px 30px 5px 30px;
    }

    .s7t-approval-list {
      padding: 10px 30px 45px;

      thead {
        box-shadow: 0 2px 4px 0 rgba(218, 218, 218, 0.5) !important;
      }
    }
  }

  .s7t-vacancy-history-table,
  .s7t-vacancy-table {
    tbody {
      tr {
        &:nth-child(odd) {
          background: #ffffff;
        }

        &:nth-child(even) {
          background: #fbfbfb;
        }
      }
    }
  }

  .s7t-vacancy-table {
    th,
    td {
      padding: 9px 0px;
      text-align: center;
      width: 60px;
    }

    thead {
      tr {
        th {
          &:nth-child(1) {
            width: 220px;
            border-right: 1px solid #dedede;
            border-left: 1px solid #dedede;
          }
        }
      }
    }

    .s7t-edit-cell {
      input {
        padding: 10px 0 10px 0;
        text-align: center;
        background: white;
      }
    }

    .s7t-vacancy-edit-ico {
      font-size: 17px;

      &:hover {
        background: #d8d8d8;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
}

/*** Events ***/
.s7t-image-cover {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background: black;
  opacity: 0.5 !important;
}

.s7t-image-grid {
  width: 100% !important;
  height: 140px !important;
  object-fit: cover !important;
}

.s7t-image-shadow {
  position: absolute !important;
  background: black !important;
  opacity: 0.5 !important;
  width: 100% !important;
  height: 100% !important;
}

.s7t-image-overStyle {
  position: absolute !important;
  top: 35% !important;
  left: 35% !important;
}

/** Loader ***/
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*** Parent Overview ***/
.s7t-parent-overview {
  .s7t-paper-notice {
    height: 50px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #4a4a4a;
    padding-top: 7px;

    .s7t-icon {
      margin-left: 19px;
      margin-right: 15px;
    }

    .s7t-icon-holder {
      display: inline-block;
      padding-left: 19px;
      padding-right: 15px;
      position: relative;
      top: 2px;
    }

    &.success {
      background: #d3e8c5;

      .s7t-icon-holder {
        color: #6db33f;
      }
    }

    button {
      margin-right: 10px;
    }
  }

  .s7t-table-wait-icon {
    background-color: #e7ac08;
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 50%;
    color: white;
  }

  .s7t-tablewaiticon {
    font-size: 20px !important;
    color: white !important;
  }

  .s7t-table-pending-visit-icon {
    margin-top: 1px;
    font-size: 24px !important;
    color: #e7ac08 !important;
  }

  .s7t-enquiry-block {
    .s7t-enquiry-content {
      padding: 1.7em;

      .s7t-payment-checklist-container {
        border-radius: 3px;

        .s7t-payment-checklist {
          padding: 1em 2em 1em 2em;

          .s7t-checklist-text {
            span {
              font-weight: 600;
              line-height: 2.5em;
            }
          }
        }
      }
    }

    .s7t-child-name {
      height: 75%;
      margin-bottom: 0;
    }

    .s7t-edit-profile-btn {
      margin-left: 1em;

      .s7t-edit-icon {
        margin-bottom: 10px !important;
      }

      span {
        margin-left: 10px;
      }
    }

    .s7t-enquiry-center {
      padding-bottom: 1em;
      margin-bottom: 1em;

      .s7t-center-data {
        border-radius: 3px;

        .s7t-center-label {
          font-size: 14px;
        }

        .s7t-center-name,
        .s7t-center-status {
          font-size: 18px;
          display: flex;

          .s7t-table-wait-icon {
            background-color: #717171;
          }

          .s7t-tablesuccessicon {
            margin-right: 10px;
          }

          .s7t-table-waiting-icon {
            background-color: #7c7c7c;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            display: inline-block;
            border-radius: 50%;
            color: white;
          }
        }
      }

      .s7t-center-actions {
        margin-top: 20px;
        text-align: right;

        .s7t-enrolment-schedule-container {
          text-align: left;
          padding-top: 20px;
          padding-bottom: 30px;

          .s7t-date-select-txt {
            position: relative;
            top: 25px;
            left: 40px;
          }
        }

        .s7t-loader {
          width: 72px;
          height: 72px;
          border-width: 9px !important;
        }

        button {
          margin-left: 30px;
        }
      }

      .s7t-enquiry-process-container {
        // margin-top: 1.7em;

        @mixin state-machine-prointer-pos($totalSteps, $start, $step) {
          @if $step >2 {
            @if $totalSteps ==3 {
              $step: $step - 1;
            }
          }

          $offset: calc(100 / $totalSteps);

          @if $step ==1 {
            left: $start * 1%;
          } @else if $step ==2 {
            left: ($start + $offset) * 1%;
          } @else {
            left: ($start + (($step - 1) * $offset)) * 1%;
          }
        }

        .s7t-process-info-box {
          position: relative;
          border-radius: 3px;
          border: 1px solid #979797;
          margin: 10px;

          $steps: 3;
          $start: 19;
          $offset: calc(100 / $steps);

          &:after {
            display: none;
            border-color: rgba(136, 183, 213, 0);
            border-width: 15px;
            margin-left: -20px;
          }

          &:before {
            display: none;
            border-color: rgba(194, 225, 245, 0);
            border-width: 30px;
            margin-left: -36px;
          }

          &.s7t-enquire {
            position: relative;
            z-index: 1;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              background-color: white;
              border-top: 1px solid #979797;
              border-left: 1px solid #979797;
              transform: rotate(45deg);
              z-index: -5 !important;
              @include state-machine-prointer-pos($steps, $start, 1);
              top: -11px;
            }

            &.s7t-4-step-process {
              &:before {
                @include state-machine-prointer-pos(4, 15, 1);
              }
            }
          }

          &.s7t-centre-visit {
            position: relative;
            z-index: 1;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              background-color: white;
              border-top: 1px solid #979797;
              border-left: 1px solid #979797;
              transform: rotate(45deg);
              z-index: -5 !important;
              @include state-machine-prointer-pos($steps, $start, 2);
              top: -11px;
            }

            &.s7t-4-step-process {
              &:before {
                @include state-machine-prointer-pos(4, 15, 2);
              }
            }
          }

          &.s7t-exploratory {
            position: relative;
            z-index: 1;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              background-color: white;
              border-top: 1px solid #979797;
              border-left: 1px solid #979797;
              transform: rotate(45deg);
              z-index: -5 !important;
              @include state-machine-prointer-pos($steps, $start, 2);
              top: -11px;
            }

            &.s7t-4-step-process {
              &:before {
                @include state-machine-prointer-pos(4, 15, 2);
              }
            }
          }

          &.s7t-registration {
            position: relative;
            z-index: 1;
            background: inherit;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              background-color: white;
              border-top: 1px solid #979797;
              border-left: 1px solid #979797;
              transform: rotate(45deg);
              z-index: -5 !important;
              @include state-machine-prointer-pos($steps, $start, 3);
              top: -11px;
            }

            &.s7t-4-step-process {
              &:before {
                @include state-machine-prointer-pos(4, 15, 3);
              }
            }
          }

          &.s7t-enrolled {
            position: relative;
            z-index: 1;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              width: 20px;
              height: 20px;
              background-color: white;
              border-top: 1px solid #979797;
              border-left: 1px solid #979797;
              transform: rotate(45deg);
              z-index: -5 !important;
              @include state-machine-prointer-pos($steps, $start, 4);
              top: -11px;
            }

            &.s7t-4-step-process {
              &:before {
                @include state-machine-prointer-pos(4, 15, 3);
              }
            }
          }
        }
      }
    }

    .s7t-other-recommended-vacancies {
      font-size: 16px;
      margin-top: 2em;
    }
  }
}

/*** ---------- ***/

/**********Child Transfer ***********/
.s7t-child-transfer {
  .s7t-transfer-tooltip {
    width: 30px;
    height: 30px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    position: absolute;
    background: white;
    transform: rotate(45deg);
  }

  .s7t-pointer-source-0 {
    top: -16px;
    left: -3%;
  }

  .s7t-pointer-source-1 {
    top: -48px;
    left: 17%;
  }

  .s7t-pointer-source-2 {
    top: -48px;
    left: 37%;
  }

  .s7t-pointer-vacancy {
    top: -64px;
    left: 7%;
  }

  .s7t-pointer-table {
    top: -48px;
    left: 57%;
  }

  .s7t-pointer-source-4 {
    top: -48px;
    left: 78%;
  }

  .s7t-pointer-source-5 {
    top: -48px;
    left: 99%;
  }

  .s7t-steps-inner-width-0 {
    max-width: 85%;
    margin: auto;
  }

  .s7t-steps-inner-width {
    max-width: 65%;
    margin: auto;
  }

  .s7t-note-blk {
    color: black;
    font-size: 12px;
    display: block;
  }

  .s7t-success-icon {
    color: #6db33f;
    font-size: 30px;
  }

  .s7t-3-dots {
    font-size: 26px;
    color: white;
    background-color: #b28406;
  }

  .s7t-success-font {
    font-size: 16px !important;
    color: #717171 !important;
    font-weight: normal !important;
  }

  .s7t-step3-status {
    margin: auto;
    width: 84%;
  }

  .s7t-transfer-status {
    font-size: 16px !important;
    color: #717171 !important;
    font-weight: 500 !important;
  }

  .s7t-transfer-chip {
    color: #e7ac08;
    text-transform: capitalize;
  }

  .s7t-status {
    margin-top: 30px !important;
    padding: 44px 88px !important;

    .s7t-title {
      font-size: 24px;
      font-weight: 500;
      color: var(--greyish-brown);
    }
  }

  .s7t-pendingreply {
    font-size: 18px;
    font-weight: normal;
    color: #f7b500;
  }
}

/*** Principal Enquiry ***/

.s7t-principal-enquiry-block {
  .s7t-search-icon {
    position: relative;
    font-size: 22px;
    top: 24px;
    right: 0px;
    padding-right: 4px;
    padding-bottom: 3px;
  }

  .s7t-filters-container {
    margin-bottom: 10px;

    .s7t-filter-formctrl {
      width: 100%;
    }

    .s7t-filter-formctrl-padding-right {
      padding-right: 10px;
    }
  }
}

/*** ---------- ***/

.s7t-event-read-only,
.s7t-admin-read-only {
  .s7t-text-input {
    &::before {
      border-bottom-style: none !important;
    }

    div {
      &::before {
        border-bottom-style: none !important;
        display: none !important;
      }
    }
  }

  .s7t-select-input {
    &::before {
      border: 0px !important;
    }

    div {
      svg {
        display: none;
      }
    }
  }

  .s7t-common-phoneNumber {
    &::before {
      border: 0px !important;
    }
  }

  .s7t-phonecode {
    &::before {
      border: 0px !important;
    }

    div {
      svg {
        display: none;
      }
    }
  }
}

/*** Registration Form  ***/
.s7t-registration-container {
  .s7t-markdown {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a;
    max-height: 350px;
    overflow: auto;
    padding: 0 24px !important;

    &.space-bottom {
      padding-bottom: 40px !important;
    }
  }

  .sn2-text-color {
    span {
      color: #333333;
    }
  }

  .s7t-step {
    .s7t-stepper-connector {
      margin: 0px 4px 0px 4px;
    }

    &:last-of-type {
      .s7t-stepper-connector {
        margin: 0;
      }
    }

    &.s7t-completed-step,
    &.s7t-active-step {
      .s7t-process-svg-holder {
        // cursor: pointer;
      }

      .s7t-stepper-connector {
        span {
          border: 3px solid #6db33f;
        }
      }
    }

    &.s7t-active-step {
      .s7t-process-svg-holder {
        border: 3px solid #6db33f;
        background: #dadada;
      }
    }
  }

  .s7t-step-padding {
    padding: 15px 50px;
  }

  .s7t-enrolment-container {
    .s7t-prereq-container {
      border-radius: 3px;
      padding: 40px 50px;

      .s7t-info-icon {
        font-size: 25px;
        position: relative;
        top: 3px;
      }

      .s7t-prereq-steps {
        border-radius: 3px;
      }
    }

    .s7t-step-content {
      max-width: 75%;
      margin: auto;
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }

    .s7t-step-margin-bottom {
      margin-bottom: 20px;
    }

    .s7t-step-panel {
      box-shadow: none;

      &::before {
        opacity: 0;
      }

      .s7t-step-panel-head {
        background-color: #dadada;
        height: 50px;
        margin: 16px 0 0;
        min-height: 50px !important;

        &.active {
          background-color: white;
          box-shadow: 0 2px 5px 0 #dadada;
        }

        .s7t-step-status-icon {
          width: 17px;
          height: 17px;
          text-align: center;
          color: white;
          border-radius: 50%;
          margin-right: 5px;

          &.completed {
            background: #6db33f;
          }

          svg {
            font-size: 15px;
          }
        }
      }
    }

    /*** subsidy-1-component ***/
    .s7t-subsidy-1-container-top {
      border-top-left-radius: 3px !important;
      border-top-right-radius: 3px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      box-shadow: none !important;
    }

    .s7t-subsidy-1-container-bottom {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border-bottom-left-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
      box-shadow: none !important;
    }

    .s7t-subsidy-1-internal-pad {
      padding-left: 12.5% !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    .s7t-subsidy-content {
      margin-left: 5.8%;
      line-height: 1.5;
      padding-right: 13%;
    }

    .s7t-household-container {
      width: 420px;
      height: 106px;
      border: 1px solid #dadada;
      border-radius: 5px;
    }

    .s7t-sub-houshold-label {
      padding-top: 10px;
    }

    .s7t-subsidy-table {
      border: 1px solid #dadada;
      border-radius: 5px !important;
      border-collapse: unset !important;
    }

    .s7t-item-props {
      font-size: 16px !important;
      color: #717171 !important;
    }

    .s7t-item-props-dark {
      font-size: 16px !important;
      font-weight: 500 !important;
      color: #717171 !important;
    }

    .s7t-const-table-cell {
      font-size: 16px !important;
      font-weight: 500 !important;
      color: #717171 !important;
      border-bottom: none !important;
    }

    .s7t-small {
      font-size: 14px !important;
      color: #717171 !important;
      font-family: 'Roboto', sans-serif;
    }

    .s7t-nric {
      font-size: 14px !important;
      color: #717171 !important;
      font-weight: 500 !important;
      font-family: 'Roboto', sans-serif;
    }

    .s7t-radio-attr {
      padding: 0 !important;
      margin-top: -4px !important;
    }

    .s7t-radio-attrjson legend {
      color: #717171 !important;
      font-family: 'Roboto' !important;
      font-size: 14px !important;
      line-height: 2.5;
    }

    .s7t-radio-span {
      margin-left: 6%;
    }

    .s7t-radio-span-step-2 {
      margin-left: 28%;
    }

    .s7t-check-attr {
      margin-top: -4px !important;
    }

    .s7t-subsidybtns-align {
      float: right;
    }

    /*** ---------- ***/

    /*** subsidy-2-component ***/

    .s7t-subsidy-2-container-top {
      border-top-left-radius: 3px !important;
      border-top-right-radius: 3px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      box-shadow: none !important;
      border-bottom: 1px solid #d8d8d8 !important;
    }

    .s7t-subsidy2btn-align {
      float: right;
    }

    .s7t-subsidy2-width {
      margin: auto;
    }

    .s7t-subsidy2-note {
      width: 100%;
      border-radius: 3px;
      margin-top: 26px !important;
      color: #4a4a4a !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
    }

    .s7t-inner-width {
      padding: 15px 46px !important;
      width: 95%;
    }

    .s7t-inner-width-step3 {
      padding: 15px 46px !important;
      width: 65%;
    }

    .s7t-inner-width-step4 {
      padding: 15px 46px !important;
      width: 90%;
    }

    .s7t-subsidy2-table {
      border: 1px solid #dadada;
      border-radius: 5px !important;
      border-collapse: unset !important;
    }

    .s7t-table-spacing {
      padding-top: 12px !important;
    }

    .s7t-no-border-bottom {
      border-bottom: none !important;
      padding-left: 0 !important;
    }

    .s7t-read-only {
      font-size: '18px';
      color: '#717171';
      font-weight: '500';
    }

    /*** ---------- ***/
  }

  .s7t-document-upload-container {
    .s7t-prereq-container {
      border-radius: 3px;
      padding: 50px 40px;

      .s7t-info-icon {
        font-size: 25px;
        position: relative;
        top: 3px;
      }

      .s7t-prereq-steps {
        border-radius: 3px;
      }
    }

    .s7t-reg-doc-up-th {
      th {
        padding: 15px;
      }
    }

    .s7t-reg-doc-up-td {
      td {
        font-size: 16px;
        padding: 20px;
      }
    }

    .s7t-upload-file-list {
      &.s7t-has-error {
        padding-bottom: 35px;
      }

      .s7t-each-uploaded-file {
        margin-top: 10px;

        button {
          min-width: 20px;
          min-height: 20px;
        }
      }
    }

    .s7t-doc-type {
      white-space: normal;
    }

    .s7t-badge-verified {
      display: block;
      width: 150px;
      font-size: 16px;
      font-weight: 500;
      color: #6db33f;
    }

    .s7t-badge-pending {
      display: block;
      width: 150px;
      font-size: 16px;
      font-weight: 500;
      color: #e7ac08;
    }

    .s7t-doc-err {
      margin-top: 5px !important;
      font-size: 15px;
      position: absolute;
      max-width: 300px;
      max-height: 50px;
      overflow: auto;
    }
  }

  .s7t-declaration-container {
    .s7t-select-input > div {
      font-size: 14px;
      margin-top: 12px;
    }

    .s7t-status-container {
      img {
        width: 16px;
      }

      .s7t-icon {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        padding: 1px;

        &.s7t-inprogress {
          background: #e7ac08;
          color: white;
        }

        &.s7t-invalid {
          color: #f3575a;
          background: transparent;
        }
      }
    }

    .s7t-declaration-sign-btn {
      // height: 20px;
    }
  }
}

.s7t-enrolment-payment-container {
  .s7t-enrolment-schedule-container {
    padding: 40px;

    .s7t-return-home-btn {
      margin-top: 30px;
      margin-right: 10px;
    }
  }

  .s7t-sub-app-divider {
    margin-top: 18px;
  }

  .s7t-sub-app-details {
    margin-top: 18px;

    span {
      font-size: 12px;
    }

    h6 {
      font-size: 16px !important;
    }
  }

  .s7t-payment-options-card {
    .s7t-pay-info-text {
      padding-bottom: 22px;
    }

    .s7t-bank-buttons {
      .s7t-dbs-posb-bank,
      .s7t-ocbc-bank,
      .s7t-other-bank {
        margin: auto;
        display: block;
        height: 90px;

        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
      }

      p {
        width: 12em;
        line-height: 0.5em;
        font-size: 16px;
        position: relative;
      }
    }
  }

  .s7t-payment-declaration {
    font-size: 14px;
    max-height: 275px;
    overflow: auto;
    border-radius: '8px';
  }

  .s7t-add-icon-applicant-btn,
  .s7t-rem-icon-applicant-btn {
    font-weight: 100;
    font-size: 13px;
  }

  .s7t-add-icon-applicant,
  .s7t-rem-icon-applicant {
    font-size: 18px;
    margin-right: 7px;
  }

  .s7t-enrolment-payment-options-container {
    margin-top: 25px;
  }

  .s7t-enrolment-pending-payment-container,
  .s7t-enrolment-unsuccess-payment-container {
    .s7t-payment-status-divider {
      margin: 29px 0px 29px 0px;
    }

    .s7t-pending-icon {
      width: 38px;
      height: 38px;
      color: white;
      border-radius: 50%;
      background: #e7ac08;
    }

    .s7t-pending-icon,
    .s7t-unsuccess-icon,
    .s7t-success-icon {
      font-size: 38px;
      position: relative;
      top: 10px;
      margin-right: 15px;
    }
  }
}

/*** ---------- ***/

/*** Sidemenu  ***/
.s7t-sidemenu > div {
  overflow: visible;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  span {
    font-size: 16px;
  }
}

.s7t-sidemenu > div > div {
  overflow: visible;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  span {
    font-size: 15px;
  }
}

.s7t-sidemenu {
  .s7t-submenu {
    height: 95vh;
    overflow: auto;
    max-width: 223px;
    min-width: 223px;
    position: fixed;
    left: 224px;

    span {
      font-size: 15px;
    }
  }

  .s7t-menu-list {
    width: 70px;
    overflow-y: auto;
    transition: 0.3s;

    &.expand {
      width: 223px;
      transition: 0.12s;

      .s7t-menu-item {
        text-align: left;
        transition: 0.5s;

        > span {
          display: inline;
        }

        img {
          margin-right: 10px;
          margin-left: 0;
        }
      }

      .s7t-menu-arrow-right {
        display: inline-block;
      }
    }

    .s7t-sidemenu-logo {
      height: 80px;
      padding: 0;

      img {
        width: 94%;
        object-fit: contain;
        max-height: 54%;
        margin: auto;
      }
    }

    .s7t-menu-item {
      height: 65px;
      font-size: 14px;
      text-align: center;
      color: #5c5c5c;
      transition: 0.8s;
      border-left: 5px solid transparent;
      padding-left: 11px;

      span {
        display: none;
        text-align: left !important;
      }

      img {
        margin-right: auto;
        margin-left: auto;
      }

      &.active {
        border-left: 5px solid #f15a22;
      }
    }

    .s7t-menu-arrow-right {
      position: absolute;
      display: none;
      right: 0px;
    }
  }

  .s7t-sidemenu-action-button {
    position: absolute;
    bottom: 30px;
    height: 28px;
    width: 28px;
    min-width: 0;
    border-radius: 50%;
    text-align: center;
    font-size: 0.25rem;
    padding: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: pointer;

    span {
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

/*** ---------- ***/

.s7t-logged-content {
  padding-left: 70px;
  width: 100%;
  transition: 0.5s;

  &.s7t-sidemenu-expand {
    padding-left: 220px;
    transition: 0.5s;
  }
}

.s7t-enquiry-label {
  font-family: 'Roboto', sans-serif;
  color: rgba(109, 107, 107, 0.699);
  font-size: 14px !important;
  font-weight: normal !important;
}

.s7t-waitlist-overview-container {
  .s7t-width-95pct {
    width: 95% !important;
  }

  .s7t-width-95px {
    width: 95px !important;
  }

  .s7t-button-circle-text {
    span {
      display: flex;
      vertical-align: middle;
      margin: auto;
      width: 10px;
      height: 10px;
      font-size: 15px;
    }
  }

  .s7t-waitlist-table-row {
    &:hover {
      cursor: pointer;
    }
  }

  .s7t-tablecell-button-circle {
    height: 25px;
    width: 25px;
    min-width: 25px;
    border-radius: 50%;
    min-height: 25px;
    display: flex;
    align-items: center;
  }

  .s7t-padding-right-2pct {
    padding-right: 2% !important;
  }

  .s7t-padding-right-table {
    thead,
    tbody {
      tr {
        th {
          padding-right: 28px !important;
        }
      }
    }
  }

  .s7t-table-head-box-shadow {
    box-shadow: 0 2px 4px 0 rgba(218, 218, 218, 0.5) !important;
  }

  .s7t-table-head-size {
    font-size: 16px !important;
  }

  .s7t-very-light-pink-background {
    background-color: #fff4e7 !important;
  }
}

.s7t-enquiry-summary-container {
  .s7t-line-height-pt5 {
    line-height: 0.5 !important;
  }

  .s7t-button-Rectangle-5 {
    text-transform: none !important;
    width: 181px !important;
    height: 48px !important;
    font-size: 18px !important;
    border-radius: 3px !important;
    box-shadow: 0 1px 2px 0 var(--very-light-pink) !important;
    background-color: #ffffff !important;
  }
}

.s7t-auth-container {
  .s7t-loging-background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
  }

  .s7t-login-grid-image {
    width: 100% !important;
    object-fit: cover;
  }

  .s7t-width-100pct {
    width: 100% !important;
  }

  .s7t-full-height {
    height: 100%;
  }

  .s7t-auth-card-shadow {
    box-shadow: 0px 3px 5px 1px #565656;
  }

  .s7t-Parent-Registration {
    font-family: Roboto !important;
    font-size: 21px !important;
    font-weight: 500 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--brownish-grey) !important;
  }

  .s7t-center-div {
    padding-left: 22% !important;
    padding-right: 22% !important;
    padding-top: 12vh !important;
  }

  .s7t-margin-bottom4pct {
    margin-bottom: 4% !important;
  }
}

.s7t-line-height-pt5 {
  line-height: 0.5 !important;
}

.s7t-line-height-3 {
  line-height: 3 !important;
}

.s7t-margin-bottom-4pct {
  margin-bottom: 4% !important;
}

.s7t-margin-right-50px {
  margin-right: 50px !important;
}

.s7t-font-size-12px {
  font-size: 12px !important;

  span {
    font-size: 12px !important;
  }
}

.s7t-font-size-14px {
  font-size: 14px !important;
}

.s7t-font-size-16px {
  font-size: 16px !important;
}

.s7t-display-flex {
  display: flex !important;
}

.s7t-very-light-pink-background {
  background-color: #fff4e7 !important;
}

.s7t-container-background {
  background-color: #f5f5f5 !important;
}

.s7t-box-shadow-none {
  box-shadow: none !important;
}

.s7t-padding-bottom-2pct {
  padding-bottom: 2% !important;
}

.s7t-note-text {
  legend {
    color: red !important;
    font-size: 12px !important;
  }
}

legend {
  color: #717171 !important;
  size: 14px !important;
}

.s7t-margin-top-1pct {
  div {
    margin-top: 1% !important;
  }
}

.s7t-width-95pct {
  width: 95% !important;
}

.s7t-width-48pct {
  width: 48% !important;
}

.s7t-width-54pct {
  width: 54% !important;
}

.s7t-padding-right-2pct {
  padding-right: 2% !important;
}

.s7t-padding-5pct {
  div {
    div {
      div:nth-child(1) {
        padding-right: 5% !important;
      }

      div:nth-child(3) {
        padding-right: 5% !important;
      }

      div:nth-child(5) {
        padding-right: 5% !important;
      }

      margin-bottom: 1% !important;
    }
  }
}

.s7t-padding-5pct-odd {
  div {
    div {
      div:nth-child(odd) {
        padding-right: 5%;
      }
    }

    margin-bottom: 1% !important;
  }
}

.s7t-padding-5pct-even {
  div {
    div {
      div:nth-child(even) {
        padding-right: 5%;
      }

      div:nth-child(1) {
        padding-right: 5%;
      }

      div:nth-child(2) {
        padding-right: 0% !important;
      }

      div:nth-child(14) {
        padding-right: 0% !important;
      }

      div:nth-child(13) {
        padding-right: 2% !important;
      }
    }

    margin-bottom: 1% !important;
  }
}

.s7t-button-min-with-161 {
  min-width: 161px !important;
}

.s7t-radio-float-left {
  div {
    display: inherit !important;

    label {
      float: left !important;

      span:nth-of-type(2) {
        font-size: 18px !important;
        color: #7f7f7f !important;
        font-weight: 500 !important;
      }
    }
  }
}

.s7t-margin-left-10pct {
  margin-left: 10% !important;
}

.s7t-font-size-18px {
  input {
    font-size: 18px !important;
  }

  font-size: 18px !important;
}

.s7t-margin-bottom-27px {
  margin-bottom: 27px !important;
}

.s7t-terrm-margin-p {
  p {
    margin: 3%;

    &:last-of-type {
      margin-bottom: 0% !important;
    }
  }

  ul:last-child {
    margin-top: 0% !important;
  }
}

.s7t-login-a {
  text-decoration: none;
}

.s7t-button-border-radius {
  border-radius: 2px !important;
}

.s7t-enquiry-sum-value {
  height: 21px;
  font-family: Roboto;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: var(--brownish-grey) !important;
}

.s7t-margin-bottom-3 {
  margin-bottom: -3% !important;
}

.s7t-enquiry-info-label {
  height: 19px;
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.s7t-line-height1p5 {
  line-height: 1.5 !important;
}

.s7t-margin {
  width: 100% !important;
}

.s7t-display-content {
  display: contents !important;
}

.s7t-button-text {
  text-transform: none !important;
  min-height: 48px !important;
}

.s7t-stepper-order {
  font-weight: bold;
  font-size: '25px';
}

.s7t-button-Rectangle-5 {
  text-transform: none !important;
  width: 181px !important;
  height: 48px !important;
  font-size: 18px !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 2px 0 var(--very-light-pink) !important;
  background-color: #ffffff !important;
}

.s7t-button-font {
  font-weight: 800 !important;
  font-size: inherit !important;
}

.s7t-margin-bottom10 {
  margin-bottom: 10% !important;
}

.s7t-padding-44 {
  padding: 44px;
}

.s7t-margin-auto {
  margin: auto !important;
}

.s7t-Rectangle-4 {
  width: 50px;
  height: 4px;
  border-radius: 2px;
  background-color: #fd8852;
  margin-bottom: 5% !important;
}

.s7t-err {
  font-family: 'Montserrat', sans-serif;
}

.s7t-footer {
  padding-top: 13px;
  font-size: 12px;
  height: 43px !important;
  bottom: 0;
  width: 100%;
  position: static;
  z-index: 999;
}

.s7t-footer-desc {
  color: #ffffff;

  a {
    color: inherit;
    font-weight: 700;
  }
}

.s7t-up {
  position: relative !important;
  float: right;
  bottom: 20px;
  width: 50px;
  height: 50px;
  min-width: 50px !important;
  min-height: 50px !important;
  opacity: 0.6;
  right: 5px;
  font-size: 25px !important;
  margin-top: -55px !important;
  text-align: center;
  border-radius: 50% !important;

  span {
    position: absolute;
  }
}

.s7t-notification-block {
  .s7t-notification-panel {
    box-shadow: none;

    &::before {
      display: none;
    }

    .s7t-notification-panel-head {
      height: 50px;
      margin: 16px 0 0 0;
      min-height: 50px !important;

      .s7t-important-label-icon {
        margin-right: 10px;
        color: transparent;

        &.important {
          color: #ffc200;
        }
      }

      .s7t-notification-time {
        color: #bbbbbb;
        position: absolute;
        right: 50px;
        font-size: 0.875rem;
      }

      &.active {
        box-shadow: 0 2px 5px 0px #dadada;
      }
    }

    .s7t-notification-detail {
      .s7t-delete-notification {
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.s7t-h1 {
  font-size: 32px !important;
  color: #4a4a4a !important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
  margin-left: 3.5% !important;
  margin-bottom: 1.5% !important;
}

.s7t-waitlist-head {
  font-size: 32px !important;
  color: #4a4a4a !important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
}

.s7t-h2 {
  font-size: 24px !important;
  color: #4a4a4a !important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
}

.s7t-h3 {
  font-size: 24px !important;
  color: red !important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
}

.s7t-h4 {
  font-size: 18px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: #717171 !important;
}

.s7t-h4-disabled {
  font-size: 18px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: #9b9b9b !important;
}

.s7t-h5 {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
  color: #717171 !important;
}

.s7t-disabled-h5 {
  font-size: 16px !important;
  color: #9b9b9b !important;
}

.s7t-h6 {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: #4a4a4a !important;
}

.s7t-h7 {
  font-size: 18px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  color: #4a4a4a !important;
}

.s7t-p {
  font-family: 'Roboto', sans-serif !important;
  color: #4a4a4a !important;
  font-weight: 500 !important;
}

.s7t-form-padding {
  margin-top: -19px;
  padding: 22px 0 !important;
}

.s7t-form-input label {
  color: #717171;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

*:focus {
  outline: none;
}

.s7t-table {
  border: 1px solid #dadada;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.s7t-border-right {
  border-right: 2px solid #dadada;
}

.s7t-table-head {
  tr {
    height: 50px !important;
  }

  th {
    padding-left: 18px;
  }
}

.s7t-table-height {
  height: 80px !important;
}

.s7t-heading-font {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #717171 !important;
}

.s7t-body-border {
  height: 81px !important;
}

.s7t-data-font {
  font-size: 16px !important;
  color: #717171 !important;
  line-height: 1.5 !important;
}

.s7t-centre-font {
  font-size: 14px !important;
  color: #717171 !important;
  line-height: 1.5 !important;
}

.s7t-list-checkbox-font {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: normal !important;
}

.s7t-body-bottom {
  th {
    font-size: 16px !important;
    color: #717171 !important;
    line-height: 1.5 !important;
  }

  // td {
  // padding-left: 18px !important;
  // }
}

.s7t-select-input {
  color: #717171 !important;
  font-size: 18px !important;
  display: block !important;
  margin-top: 0px !important;
  margin-bottom: 0 !important;

  div {
    padding-right: 3px;
    padding-left: 3px;
  }
}

.s7t-change-b > div {
  input {
    margin-top: 8px;
  }
}

.s7t-change-c > div > div {
  margin-top: 8px;
}

.s7t-checkbox-icon span svg {
  font-size: 20px !important;
  margin-left: -20px;
  margin-top: -3px;
  margin-right: 6px;
}

.s7t-checkbox-icon-all span svg {
  font-size: 20px !important;
  margin-left: -14px;
  margin-top: -3px;
  margin-right: 6px;
}

.s7t-checkbox-icon span span {
  margin-left: -18px !important;
}

.s7t-text-decoration-none {
  text-decoration: none !important;
}

.s7t-underline {
  z-index: 10;
  text-decoration: underline !important;
}

.s7t-warning {
  font-size: 18px !important;
  color: $primaryColor;
  vertical-align: sub !important;
}

.s7t-warning-align {
  margin-left: 4px;
  margin-top: 4px;
}

.s7t-date-primary-color {
  font-weight: 700;
}

/*** parent-center-search-component ***/
.s7t-parent-search-container {
  padding: 0% 30px !important;
  width: 100%;

  .s7t-toppadding-margin {
    margin-top: -5px;
  }

  .s7t-card-internal-pad {
    padding: 0px 32px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .s7t-block-display {
    display: block !important;
    margin-top: 0px !important;
  }

  .s7t-table-wait-icon {
    background-color: #f5a623;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
  }

  .s7t-tablesuccessicon {
    font-size: 35px !important;
    color: #6db33f !important;
  }

  .s7t-tablewaiticon {
    font-size: 29px !important;
    color: white !important;
  }

  .s7t-legend-success-icon {
    font-size: 18px !important;
    color: #6db33f !important;
  }

  .s7t-legend-wait-icon {
    font-size: 15px !important;
    color: white !important;
    vertical-align: top;
  }

  .s7t-tabledesc-container {
    padding-top: 0 !important;
    padding-bottom: 16px !important;
  }
}

.s7t-legend-wait-icon {
  font-size: 15px !important;
  color: white !important;
  vertical-align: top;
}

/*** ---------- ***/

/*parent-enquiry-component*/
.s7t-parent-enquiry-container {
  padding: 0% 30px !important;
  width: 100%;

  .s7t-margin-35 {
    margin: 51px 0 !important;
  }

  .s7t-margin-38 {
    margin: 38px 0px !important;
  }

  .s7t-table-wait-icon {
    background-color: #f5a623;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
  }

  .s7t-tablesuccessicon {
    font-size: 35px !important;
    color: #6db33f !important;
  }

  .s7t-tablewaiticon {
    font-size: 29px !important;
    color: white !important;
  }

  .s7t-legend-success-icon {
    font-size: 18px !important;
    color: #6db33f !important;
  }

  .s7t-legend-wait-icon {
    font-size: 15px !important;
    color: white !important;
  }

  .s7t-tabledesc-container {
    padding-top: 0 !important;
    padding-bottom: 16px !important;
  }
}

/*** ---------- ***/

/* Child Card */
.s7t-child-info-card {
  .s7t-child-name-size {
    font-size: 34px !important;
    font-weight: 500 !important;
    color: #4a4a4a !important;
  }

  .s7t-font-size-12px {
    font-size: 12px !important;
    color: #585858 !important;
  }

  .s7t-enquiry-label {
    font-family: 'Roboto', sans-serif;
    color: #717171;
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .s7t-child-action-btn {
    text-transform: capitalize !important;
    font-size: 16px !important;
    font-weight: normal !important;
  }

  .s7t-enquiry-sum-value {
    font-family: 'Roboto', sans-serif;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #717171;
  }
}

/*** --------- ***/

/* Class Management */
.s7t-class-management-container,
.s7t-child-profile-container {
  .s7t-application-detail-info {
    .s7t-info-icon {
      font-size: 35px;
      margin: 0px 15px 0px 0px;
      float: left;
    }

    .s7t-info-txt {
      float: left;
      margin-top: 2px;
    }

    .s7t-info-button {
      margin-top: 3px;
    }
  }

  .s7t-temperature-reading {
    width: 25%;
    border-right: 1px solid #d5d5d5;

    &:last-of-type {
      border-right: none;
    }
  }

  .s7t-temperature-green {
    color: #717171;
    font-size: 18px;
  }

  .s7t-temperature-yellow {
    color: #ff8f6e;
    font-size: 18px;
  }

  .s7t-margin-top-typography {
    margin-top: 8px !important;
  }

  .s7t-margin-top-trial-status {
    margin-top: 2% !important;
  }

  .s7t-margin-top-trial-day {
    margin-top: 4% !important;
  }

  .s7t-hover-override {
    font-size: 16px;
    padding: 0;
    font-weight: normal !important;
    text-transform: none;
  }

  .s7t-hover-override:hover {
    background-color: transparent !important;
  }

  .s7t-button-circle {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .s7t-grid-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .s7t-horizontal-list-scroll {
    overflow: hidden;
  }

  .s7t-arrow-button {
    align-self: center;
    height: 80px;
  }

  .s7t-card-promotechild {
    overflow: visible;
    position: relative;
    margin: 30px 10px 10px 10px;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    width: 210px;
    padding-top: 15px;
  }
  .s7t-card-media-promotechild-contain {
    display: flex;
    justify-content: center;
  }
  .s7t-card-media-promotechild {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
  }

  .s7t-promote-count {
    white-space: initial;
    font-size: 14px;
    color: #303030;
    overflow: hidden;
  }

  .s7t-class-card-avatar {
    position: absolute;
    display: inline-flex;
    height: 50px;
    width: 50px;
    margin-left: calc(50% - 25px);
    margin-top: -25px;
    border-radius: 50%;
    align-items: center;
  }

  .s7t-class-card-title {
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  .s7t-class-card-subtitle {
    font-weight: normal;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  .s7t-class-card-typo {
    display: inline-block;
  }

  .s7t-card-top {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #d8d8d8 !important;
    overflow: visible !important;
  }

  .s7t-card-bottom {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    box-shadow: none !important;
  }

  .s7t-cls-mgmt-heading {
    font-size: 32px;
    color: #4a4a4a;
    font-weight: 500;
    margin: 24px 0px !important;
    padding-top: 16px !important;
  }

  .s7t-class-card-container {
    padding-left: 10% !important;
    width: 90% !important;
  }

  .s7t-optional-span {
    font-size: 14px !important;
    color: #bbbbbb !important;
  }

  .s7t-classmgmt-table-style {
    box-shadow: none;
    border-radius: 0;
  }

  .s7t-classmgmt-assign-style {
    box-shadow: none;
    border-radius: 0;
  }

  .s7t-classmgmt-table-style thead {
    white-space: pre;
  }

  .s7t-class-mgmt-tableRow-data {
    height: 76px;
    padding: 15px 0px !important;

    .s7t-class-mgmt-tableRow {
      font-size: 16px !important;
      color: #4a4a4a !important;
      font-weight: normal !important;
    }
  }

  .s7t-create-new-class-btn {
    margin: 9px auto 0 0 !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    height: 48px !important;
    box-shadow: 0 1px 2px 0 #dadada !important;
  }

  .s7t-create-class-btn {
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    box-shadow: 0 1px 2px 0 #dadada !important;
  }

  .s7t-placeholder-color {
    color: #dadada !important;
  }

  .s7t-centre-list-table-sub-font {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #717171 !important;
  }

  input[type='date'] {
    position: relative !important;
    margin-left: 1px !important;
  }

  .s7t-close-btn {
    min-height: 0 !important;
    height: 25px !important;
    width: 25px !important;
  }

  .s7t-class-paper {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .s7t-child-particulars .s7t-class-paper {
    &::before {
      background-color: white;
    }
  }

  .s7t-class-detail-list-tags {
    box-shadow: none !important;
  }

  .s7t-class-detail-appbar {
    height: 50px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    color: #717171 !important;
    font-weight: 500 !important;
    margin: 0px !important;
  }

  .s7t-table-cell {
    border-bottom: 'none';
    font-weight: 'bold';
    font-size: 16px !important;
    color: #717171 !important;
  }

  .s7t-helpicon1 {
    color: #458ccb !important;
    position: relative !important;
    top: -26px !important;
    left: 157px !important;
  }

  .s7t-helpicon2 {
    color: #458ccb !important;
    position: relative !important;
    top: 7px !important;
    left: 13px !important;
  }

  .s7t-uploadDisable {
    pointer-events: none;
    opacity: 0.6;
  }

  .s7t-upcoming-tab-sub-font {
    color: rgba(84, 84, 84, 0.85) !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif;
  }

  .s7t-promote-paper {
    background-color: #fbfbfb;
    margin-bottom: 35px !important;
    margin-top: 2px !important;
  }

  .s7t-promo-card-border {
    border-bottom: 1px solid #dadada !important;
  }

  .s7t-paper-shadow {
    box-shadow: 0px 1px 1px 0px #c7c7c7 !important;
  }

  .s7t-padding-top-5pct {
    padding-top: 5% !important;
  }
}

/* Class Management */

/*Waitlist-applicant-details*/
.s7t-waitlist-container {
  .s7t-blur {
    filter: grayscale(100%);
  }

  .s7t-cards {
    box-shadow: none !important;
    border-radius: 3px !important;
    padding: 0px 32px;
  }

  .s7t-table-cell {
    border-bottom: 'none';
    font-weight: 'bold';
    font-size: 16px !important;
    color: #717171 !important;
  }

  .s7t-waitlist-table-available {
    font-size: 16px !important;
    color: #717171 !important;
  }

  .s7t-waitlist-table-onwait {
    font-size: 16px !important;
    color: #bbbbbb !important;
  }
}

/*** ---------- ***/

/*** Pagination ***/
.s7t-pagination {
  padding: 10px;

  .s7t-page-no-container {
    margin: auto;
    display: table;
    max-width: 100%;
    table-layout: fixed;

    span {
      display: table-cell;
      text-align: center;
    }
  }

  .label {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #717171;
  }

  .s7t-current-page {
    color: white;
    width: 2em;
    text-align: center;
    border-radius: 3px;
  }

  .s7t-page-no {
    width: 2em;
    text-align: center;
    border-radius: 3px;
  }

  .s7t-page-nav {
    font-size: 16px;
    font-weight: 500;
    color: #bbbbbb;
  }
}

.s7t-non-clickable {
  cursor: not-allowed !important;
  pointer-events: visible !important;
}

/* Black list */

.textField-16B {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #717171 !important;
}

.s7t-add-blacklist {
  margin-bottom: 70px;

  .s7t-input-label-18 {
    font-size: 18px !important;
    font-weight: normal !important;
    color: #717171 !important;
  }

  .bread-crumb {
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .s7t-add-blacklist-title {
    font-size: 32px !important;
    font-weight: 500px !important;
    color: #4a4a4a !important;
  }

  .textField-without-border {
    margin: 0px !important;

    ::before {
      border: none !important;
      color: #717171 !important;
    }

    label {
      font-size: 18px !important;
      font-weight: normal !important;
      color: #717171 !important;
    }

    input {
      font-size: 16px !important;
      font-weight: bold !important;
      color: #717171 !important;
    }
  }

  .s7t-add-blacklist-card {
    .s7t-blacklist-border {
      .s7t-blacklist-select {
        font-size: 16px !important;
        font-weight: bold !important;
        color: #717171 !important;
      }

      label {
        font-size: 18px !important;
        font-weight: normal !important;
        color: #717171 !important;
      }

      input {
        font-size: 16px !important;
        font-weight: bold !important;
        color: #717171 !important;
      }
    }

    .s7t-blacklist-select {
      font-size: 16px !important;
      font-weight: bold !important;
      color: #717171 !important;
    }

    label {
      font-size: 18px !important;
      font-weight: normal !important;
      color: #717171 !important;
    }

    input {
      font-size: 16px !important;
      font-weight: bold !important;
      color: #717171 !important;
    }
  }
}

.s7t-blacklist-review-content {
  .s7t-blacklist-review-content-names {
    border: none !important;
    padding: 5px 15px 0px 30px !important;
  }

  .s7t-blacklist-review-content-name-value {
    border: none !important;
    // padding: 0px 0px 0px 30px !important;
    font-size: 14px !important;
    text-align: center;
    font-weight: bold !important;
    color: #717171 !important;
  }
}

.s7t-remove-blacklist-hover {
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
}

.s7t-blacklist-mgnt-overview {
  .s7t-blcklst-mgt-orv-title {
    font-size: 32px !important;
    font-weight: 500px !important;
    color: #4a4a4a !important;
  }

  .s7t-blacklist-table {
    .s7t-blacklist-table-head {
      font-size: 16px !important;
      color: #717171 !important;
      font-weight: bold !important;
    }

    .s7t-waitlistCard-tableRow-data {
      height: 60px !important;

      .s7t-waitlistCard-tableRow {
        font-size: 16px !important;
        color: #717171 !important;
        font-weight: normal;

        .s7t-waitlistCard-tableRow-contents {
          // max-width: 350px !important;
          .s7t-blacklist-table-remove-btn :hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

/*** ---------- ***/

/*** Calendar ***/
.s7t-principal-calendar {
  .s7t-disabled {
    color: #dadada !important;
  }

  .rbc-month-view,
  .rbc-time-view {
    background-color: white !important;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-month-row {
    overflow: auto;
  }

  .rbc-timeslot-group {
    min-height: 60px;
  }

  .rbc-day-slot .rbc-event {
    border: none !important;
    margin: 0px 2px;
  }

  .rbc-event {
    border-radius: 0 !important;
  }

  .rbc-day-slot {
    margin: 0;
  }

  .rbc-events-container {
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 4px !important;
    top: 0;
  }

  .rbc-event {
    box-sizing: border-box !important;
    -webkit-box-shadow: none;
    box-shadow: none !important;
    margin: 0;
    padding: 2px 5px;
    border-radius: 5px !important;
    cursor: pointer;
    width: 100%;
    text-align: left;

    .rbc-event-content {
      text-align: center;

      p {
        margin: 1px;
      }
    }
  }

  .rbc-calendar .rbc-month-view .rbc-month-row,
  .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content,
  .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-date-cell {
    flex: 1;
  }

  .s7t-notify-pending-visits {
    background: #f3575a;
    width: 6px;
    height: 6px;
    position: absolute;
    display: block;
    right: 10%;
    border-radius: 50%;
    margin-top: 2px;
  }

  .week-event {
    font-size: 12px;

    .event-title {
      font-size: 14px;
      text-align: left;
      font-weight: 500;
      margin-top: 2px;

      .time-label-wrapper {
        font-size: 12px;
        padding-top: 4px !important;

        .time-label {
          font-size: 12px;
          text-align: left;
          font-weight: 200;
        }
      }
    }

    .event-user-label {
      padding-top: 5px !important;
      font-size: 12px;
      text-align: left;
      font-weight: 200;
    }
  }
}

.s7t-week-day {
  border-bottom: 1px solid #dadada;
}

/* Calendar */

/* Settings Waitlist Parameters  */
.s7t-tableRow-grey {
  background-color: #fbfbfb;
}

.s7t-waitlistCard-title {
  font-weight: 500;

  &.s7t-fontSize-24 {
    font-size: 24px;
  }

  &.s7t-fontSize-32 {
    font-size: 32px;
  }
}

.s7t-waitlistCard-tableHead {
  font-weight: bold !important;
  font-size: 16px !important;
  color: #717171 !important;
}

/*** ---------- ***/

/*** Settings ***/

.s7t-settings-container {
  .s7t-general-settings {
    .s7t-link {
      text-decoration: none;
    }
  }
}

/*** Mass Select Component ***/
.s7t-mass-select-container {
  .s7t-table-title {
    margin-bottom: 7px;
  }

  .s7t-available-items,
  .s7t-added-items {
    overflow-y: scroll;
    border-radius: 3px;
    height: 180px;

    span {
      margin: 1px;
      display: block;
      font-size: 14px;
      border-radius: 3px;
      text-align: center;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 1px 2px darkgray;
      }

      &.active {
      }
    }
  }

  .s7t-item-asgnmt-actions {
    height: 100%;

    button {
      top: 40%;
      margin: auto;
      display: block;
      position: relative;
    }
  }
}

/*** Mass Select Component ***/

/*** Bus Settings Page ***/
.s7t-bus-child-add {
  min-height: 350px;
  max-height: 100vh;
}

.s7t-bus-management-container {
  .s7t-bus-list-tabs {
    display: block;
    box-shadow: none;
  }

  .s7t-bus-detail-container {
    .s7t-available-children,
    .s7t-added-children {
      overflow-y: scroll;
      border-radius: 3px;
      height: 180px;

      span {
        margin: 1px;
        display: block;
        border-radius: 3px;
        text-align: center;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 1px 2px darkgray;
        }

        &.active {
        }
      }
    }

    .s7t-chldrn-asgnmt-actions {
      button {
        top: 28%;
        margin: auto;
        display: block;
        position: relative;
      }
    }
  }

  .s7t-bus-listing-container {
    padding: 2px;
    overflow-x: auto;
  }

  .s7t-bus-cancel-btn {
    display: flex;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-decoration: none;
    border-radius: 3px;
    padding: 8px;
  }
}

/* --- Bus Settings Page --- */
/*** Centre Settings Page ***/
.s7t-centre-management-container {
  .s7t-centre-detail-container {
    .s7t-nearby-postcode-chip-container {
      padding: 2px 0px 10px 0px;
    }

    .s7t-nearby-pc-chip,
    .s7t-level-select-chip {
      margin: 0 5px;
    }
  }

  .s7t-centre-listing-container {
    overflow-x: scroll;
  }
}

/* --- Centre Settings Page --- */

/*** Level Settings Page ***/
.s7t-level-management-container {
  .s7t-level-detail-container {
  }

  .s7t-level-listing-container {
    overflow-x: scroll;
  }
}

/* --- Level Settings Page --- */

/****************Fee management****************************/
/////
/***************Fee ManageList Listing ****************/
.s7t-fee-mgmt-list-container {
  margin-bottom: 60px;

  .s7t-fee-mgmt-list-title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
  }

  .s7t-fee-mgmt-list-filter {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .filter1 {
      margin-right: 20px;
    }

    .filter2 {
    }

    .filter1-title {
      font-size: 14px;
      color: #717171;
    }

    .filter1-select {
    }
  }

  .s7t-fee-pending {
    font-size: 14px;
    font-weight: 500;
    color: #f5a623;
  }

  .s7t-fee-approved {
    font-size: 14px;
    font-weight: 500;
    color: #6db33f;
  }

  .s7t-fee-rejected {
    font-size: 14px;
    font-weight: 500;
    color: #f3575a;
  }

  .s7t-fee-mgmt-list-tags {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
  }

  .s7t-fee-mgmt-list-appbar {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2) !important;
    margin-top: 20px;
  }

  .s7t-fee-mgmt-list-iconstyle {
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .s7t-fee-mgmt-fonttab {
    font-family: Roboto;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #717171 !important;
  }

  .s7t-feelist-headerstyle {
    font-size: 14px !important;
    font-weight: bold !important;
    color: #717171 !important;
  }

  .s7t-feelist-contentstyle {
    font-size: 14px !important;
    color: #717171 !important;
  }

  .s7t-tarGenerator-iconfont {
    color: $primaryColor;
    font-size: 14px;
    font-weight: 700;
    margin-right: 10px;
  }

  .s7t-feelist-contentactive {
    font-size: 14px !important;
    color: #04f110 !important;
  }

  .s7t-fee-mgmt-list-popup {
    display: flex !important;
    flex-direction: column !important;
  }

  .s7t-table-content {
    font-size: 50px !important;
  }

  .s7t-table-head {
    font-size: 50px !important;
  }
}

/***********Enqiury Checklist *************/
.s7t-ScheduledDetails {
  .s7t-ScheduledDetails-cell {
    display: flex !important;
    flex-direction: row !important;
  }

  .resultfont {
    font-weight: 600 !important;
  }

  .mainfont {
    margin: 12px 0px !important;
  }
}

/*******Fee Management Modal Current popview  ****/
.s7t-feemodal {
  .s7t-fm-gridone {
    margin-bottom: 20px !important;
  }

  .s7t-fm-headstyle {
    font-size: 12px;
    color: var(--brownish-grey);
  }

  .s7t-fm-rowstyle {
    font-size: 16px;
    font-weight: 500;
    color: var(--brownish-grey);
  }

  .s7t-feemodal-tagshadow {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
  }

  .s7t-feemodal-appbar {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2) !important;
    margin-top: 20px;
  }
}

/*  Fee Management Edit-tier */
.s7t-edit-tier {
  .s7t-edit-feetier {
    box-shadow: none !important;
    background-color: transparent !important;
    border-bottom: 2px solid #f5f5f5 !important;
  }

  .feetier-students {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #717171 !important;
    position: absolute;
    right: 5px;
    top: 40px;

    .feetier-total-students {
      font-size: 18px !important;
      font-weight: 500 !important;
    }

    .feetier-view-button {
      color: #e86b32 !important;
      font-size: 16px !important;
    }
  }

  .s7t-feetier-title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    color: var(--greyish-brown);
  }

  .s7t-feetier-table {
    .s7t-feetier-table-headRow {
      color: #717171 !important;
      font-size: 16px !important;
      font-weight: bold !important;
    }

    .s7t-feetier-table-bodyRow {
      color: #717171 !important;
      font-size: 16px !important;
      font-weight: normal !important;
    }
  }
}

/*** --------------------- ***/
.no-border-textField {
  ::before {
    border-bottom: none !important;
  }
}

.s7t-connector-intro {
  top: 28px !important;
}

.s7t-label-result {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #717171 !important;
}

.s7t-label-result-edit {
  font-size: 18px;
  font-weight: 500;
  margin-top: 19px;
  color: #717171;
}

.no-image {
  background-color: #f5f5f5;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-image-icon {
    width: 50px;
    height: 42.5px;
    margin: 0 auto;
  }
}

.no-image-round {
  border-radius: 50% !important;
}

.s7t-class-details {
  .s7t-cls-dtl-form {
    input {
      cursor: pointer;

      &[readonly] {
        cursor: text;
      }
    }

    .s7t-label {
      margin-bottom: 5px;
    }
  }

  .s7t-border-color {
    border: solid 5px $primaryColor !important;
  }

  .s7t-class-detail-appbar {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .s7t-class-details-profilecard {
    position: relative;

    .s7t-profile-details {
      .s7t-profile-image {
        position: absolute;
        border: solid 1px $primaryColor;
        bottom: 70px;
        border-radius: 50%;
        margin-left: 20px;
      }

      .s7t-cover-image {
        width: 100%;
        height: 250px;
        position: relative;
      }

      .s7t-Button {
        position: absolute;
      }

      .s7t-first-grid {
        margin-left: 226px;
      }

      .s7t-second-grid {
        margin-left: 226px;
      }
    }
  }
}

.s7t-fullcontent {
  padding: 0px !important;
}

.s7t-cls-dtl-form {
  input {
    font-size: 18px !important;
    color: #717171 !important;
    font-weight: 500 !important;
    width: max-content;
  }

  label {
    font-size: 14px !important;
    color: #717171 !important;
    font-weight: normal !important;
    width: max-content !important;
  }
}

/**** User & Role Management ****/
.s7t-user-management-container {
  .s7t-new-user-container {
    form {
      .s7t-create-user-ip {
        height: 109px;
        margin-bottom: 2px !important;

        &.s7t-associated-role {
          & > div {
            position: relative;
            top: 30px;
          }
        }

        &.s7t-email {
          & > div {
            position: relative;
            top: 10px;
          }
        }
      }

      .s7t-create-user-ip-align {
        position: relative;
        top: 13px;
      }

      .s7t-create-user-ip-phone {
        position: relative;
        top: 4px;
      }
    }

    .s7t-width-95pct {
      width: 95% !important;
    }

    .s7t-margin-right-15pct {
      margin-right: 15% !important;
    }
  }

  .s7t-table-head-box-shadow {
    box-shadow: 0 2px 4px 0 rgba(218, 218, 218, 0.5) !important;
  }

  .s7t-very-light-pink-background {
    background-color: #fff4e7 !important;
  }

  .s7t-border-radius {
    border-radius: 0% !important;
  }

  .s7t-button-text-transform {
    text-transform: none !important;
  }

  .s7t-width-95pct {
    width: 95% !important;
  }

  .s7t-margin-right-15pct {
    margin-right: 15% !important;
  }

  .s7t-roleheader-rectangle {
    box-shadow: 0 2px 4px 0 rgba(218, 218, 218, 0.5);
    background-color: white !important;
    margin-bottom: 30px;
  }

  .s7t-roleheader-rectangle-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: grey !important;
    margin-bottom: 4px;
  }

  .s7t-roleheader-rectangle-info {
    // width: 71px;
    // height: 21px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 550 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: grey !important;
  }

  .s7t-roleheader-rectangle-margin {
    margin: 12px 8px;
  }
}

/**** Common Child Table ****/
.s7t-common-table-header-padding {
  padding: 4px 0px 4px 24px !important;
}

.s7t-common-child-table-cell-heading {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.s7t-common-child-table-cell-content {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400 !important;
}

.s7t-common-child-table-cell-padding {
  padding-right: 6px !important;
}

/*** --------------------- ***/

/********************GRID VIEW*********************/
.s7t-selection-border {
  border: solid 5px $primaryColor !important;
}

.s7t-cover-photo-hover {
  .s7t-hover-container {
    opacity: 1 !important;
  }

  .s7t-change-image-button {
    opacity: 1 !important;
    background: $secondaryColor !important;
    border: solid 1px $primaryColor;
  }

  .s7t-drag-drop-button {
    opacity: 1;
  }
}

.s7t-grid-upload-container {
  .s7t-edit-image-button {
    .s7t-uploaded-img {
      .s7t-uploaded-img-hover {
        display: block !important;
        position: absolute;
        bottom: 0%;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.s7t-button-hover {
  opacity: 0.5;

  &:hover {
    opacity: 1;
    border: solid 1px $primaryColor;
  }
}

.s7t-select-button {
  font-size: 12px !important;
  color: $primaryColor !important;
  font-weight: 500 !important;
}

.S7t-hover-onButton {
  &:hover {
    border: solid 2px $primaryColor;
    background: $secondaryColor !important;
    color: $primaryColor;
  }
}

.s7t-boxborder {
  height: 544px;
}

.s7t-profile-photo-view {
  bottom: -158px;
}

/*** --------------------- ***/

.s7t-common-child-table {
  .s7t-common-child-table-row {
    height: 76px !important;
    padding: 10px 0px !important;

    .s7t-common-child-table-cell {
      padding: 15px 20px !important;

      .s7t-common-child-table-sub-cell {
        font-size: 14px !important;
        color: #717171 !important;
        padding-top: 10px;
        padding-right: 5px;
      }

      .s7t-common-child-table-sub-cell-bold {
        font-size: 14px !important;
        word-break: break-all;
        font-weight: 500 !important;
        color: #717171 !important;
      }
    }
  }
}

/**** School Config ****/
.s7t-school-config-container {
  .s7t-color-picker {
    width: '100%';
  }

  .s7t-school-logo-container {
    border: 1px solid #dadada;
  }
}

/*** --------------------- ***/
#registration_state {
  h4 {
    padding-left: 20px;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 100vh;
  }

  .editor {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    > textarea {
      flex-grow: 1;
      font-family: Monaco, monospace;
      font-size: 16px;
      background: #161818;
      color: #fefefe;
      line-height: 1.2;
    }
  }

  #cy {
    width: 100%;
    min-height: 1500px;
    flex-grow: 1;
  }
}

/**** User & Role Management ****/
.s7t-holiday-management-container {
}

/************ Attendance Management **************/
.s7t-attendance-class {
  .s7t-attendance-bar {
    width: 100%;
    height: 109px;
  }

  @media (max-width: 999px) {
    .text-style {
      font-size: 10px !important;
    }
  }

  .s7t-icon-align {
    border-radius: 50%;
    background: aliceblue;
    max-height: 50px;

    svg {
      display: block;
      margin: auto;
      height: 100%;
    }
  }
}

/*************************************************/

/***********WITHDRAWAL REQUEST****************/
.s7t-withdrwal-request {
  .s7t-steps-inner-width {
    max-width: 75%;
    margin: auto;
  }

  .pointer-principal {
    width: 30px;
    height: 30px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    position: absolute;
    background: white;
    top: -15px;
    transform: rotate(45deg);
    left: 33%;
  }

  .pointer-create {
    width: 30px;
    height: 30px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    position: absolute;
    background: white;
    top: -15px;
    transform: rotate(45deg);
    left: 17%;
  }

  .pointer-hq {
    width: 30px;
    height: 30px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    position: absolute;
    background: white;
    top: -15px;
    transform: rotate(45deg);
    left: 48.5%;
  }

  .pointer-doc {
    width: 30px;
    height: 30px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    position: absolute;
    background: white;
    top: -15px;
    transform: rotate(45deg);
    left: 64.5%;
  }

  .pointer-complete {
    width: 30px;
    height: 30px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    position: absolute;
    background: white;
    top: -15px;
    transform: rotate(45deg);
    left: 80%;
  }

  .s7t-stepper-connector span {
    margin: 16px !important;
  }

  .s7t-transfer-status {
    font-size: 16px !important;
    color: #717171 !important;
    font-weight: normal !important;
  }

  margin-bottom: 24px;

  .s7t-withdrawal-paper {
    .s7t-main-container {
      padding: 48px 90px 48px 90px;
    }
  }

  .s7t-withdraw-grid {
    padding: 71px 90px;

    .s7t-container {
      .s7t-grid-item {
        padding: 0px 0px 0px 90px;
      }
    }

    .s7t-label-result {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .s7t-withdraw-new {
    margin: auto;

    .s7t-label-result {
      font-size: 18px;
      font-weight: 500;
    }

    .s7t-menu-item {
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--brownish-grey);
    }
  }

  .s7t-checkbox {
    padding: 5px 0px;
    margin-right: 7px;
  }

  .s7t-pr-checkbox {
    font-size: 14px !important;
    color: #717171 !important;
    font-weight: normal !important;
    white-space: nowrap;
  }

  .s7t-button-create {
    .button-approve {
      font-size: 18px;
      font-weight: 500;
    }

    .button-cancel {
      margin-right: 45px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .s7t-withdraw-document {
    .s7t-container {
      padding: 48px 90px 48px 90px;

      .s7t-grid-container {
        padding: 71px 90px 71px 90px;

        .s7t-doc-label {
          font-size: 14px !important;
          color: #717171 !important;
          font-weight: normal !important;
        }

        .s7t-doc-link {
          font-size: 16px !important;
          color: #717171 !important;
          font-weight: normal !important;
        }

        .s7t-each-link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }
      }
    }
  }

  .s7t-withdrawal-success {
    .s7t-container {
      padding: 48px 90px 48px 90px;

      .s7t-grid {
        padding: 71px 140px 71px 90px;

        .s7t-title {
          font-size: 18px;
          font-weight: 500;
        }

        .s7t-result {
          font-size: 16px !important;
          color: #717171 !important;
          font-weight: normal !important;
        }
      }
    }
  }

  .s7t-withdraw-hq {
    .s7t-button-grid {
      color: $secondaryColor;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;

      .button-approve {
        font-size: 18px;
      }

      .button-cancel {
        margin-right: 45px;
        font-size: 18px;
      }
    }

    margin-bottom: 24px;

    .s7t-hq-container {
      padding: 48px 90px 48px 90px;

      .s7t-hq-grid {
        border: solid 1px #979797;
        padding: 71px 90px 71px 90px;

        .s7t-hq-label {
          font-size: 14px !important;
          color: #717171 !important;
          font-weight: normal !important;
        }

        .s7t-hq-label-result {
          font-size: 18px;
          font-weight: 500 !important;
          color: #717171 !important;
        }

        .s7t-hq-checkbox {
          font-size: 14px !important;
          color: #717171 !important;
          font-weight: normal !important;
          white-space: nowrap;
        }
      }
    }
  }
}

.s7t-subsidy-upload-listing,
.s7t-giro-upload-listing {
  .s7t-row-item {
    line-height: 30px;
  }

  .s7t-failed {
    color: #fd0202;
  }

  .s7t-progress {
    color: #0086bf;
  }

  .s7t-log {
    font-size: 12px;
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .s7t-error-message {
    display: inline-block;
    margin-right: 15px;
  }
}

.s7t-0-border-bottom {
  border-bottom: 0px !important;
}

.s7t-broadcast-container,
.s7t-create-broadcast-container {
  .s7t-broadcast-unread {
    width: 12px;
    height: 12px;
    display: block;
    right: 10%;
    top: 10%;
    border-radius: 50%;
  }

  .s7t-broadcast-messages-container {
    max-height: 70vh;
    overflow-y: scroll;
  }

  .s7t-broadcast-details-container {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .s7t-attachment-div {
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0.08);
    min-height: 6em;
  }

  .s7t-broadcast-msg {
    min-height: 130px !important;
  }

  .s7t-broadcast-message-details {
    position: relative;

    &:hover {
      .s7t-draft-hover {
        display: block !important;
        position: absolute;
        bottom: 0%;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

/*** --------------------- ***/

/*** Principal Dashboard ***/
.s7t-dashboard-container {
  .s7t-content-wrapper {
    padding: 25px;
  }

  .s7t-content-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .s7t-pending-request {
    .pending-request-link {
      text-decoration: none;
    }

    .pending-request-button {
      width: 100%;
      padding: 20px;
      justify-content: space-between;
      margin-bottom: 25px;
      text-align: left;
      white-space: pre-line;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .s7t-schedule-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #02a2fb;
    padding: 20px 15px;
    margin-bottom: 10px;
    border-radius: 5px;

    & .schedule-icon {
      margin-right: 15px;
      font-size: 30px;
    }

    & svg,
    p {
      color: #fff;
    }

    &.centre-visit {
      background-color: #ff5660;
    }
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

/*** Dotted Loader for loading ***/
.dotted_loader {
  display: inline-block;
  position: relative;
  height: 10px;
  width: 10px;

  .dots_style {
    color: red;
    font-size: 6px;
  }
}

.dotted_loader div {
  position: absolute;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.dotted_loader div:nth-child(1) {
  left: 6px;
  animation: dotted_loader1 0.6s infinite;
}

.dotted_loader div:nth-child(2) {
  left: 6px;
  animation: dotted_loader2 0.6s infinite;
}

.dotted_loader div:nth-child(3) {
  left: 26px;
  animation: dotted_loader2 0.6s infinite;
}

.dotted_loader div:nth-child(4) {
  left: 45px;
  animation: dotted_loader3 0.6s infinite;
}

@keyframes dotted_loader1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes dotted_loader3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes dotted_loader2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(14px, 0);
  }
}

/** Notification History **/
.s7t-notification-Button {
  min-width: 30px !important;
}

.s7t-button-border {
  border-bottom: solid;
  border-radius: 0px;
}

.s7t-tabClass {
  height: 50px !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  background: white;
  color: #717171 !important;
  font-weight: 500 !important;
  margin: 0px !important;
}

.s7t-notification {
  position: absolute;
  z-index: 1;
  min-width: 345px;
  top: 200%;

  .s7t-new-notification {
    font-weight: bold;

    &:before {
      content: 'new';
      color: var(--color-primary);
      padding-right: 2px;
      font-size: 11px;
      font-weight: 700;
      display: block;
      text-align: center;
    }
  }

  .s7t-divider {
    height: 2px !important;
    background-color: rgba(255, 255, 255, 1) !important;
  }

  .s7t-scroll-container {
    overflow: auto;
    max-height: 75vh;

    &.overflow-x-hidden {
      overflow-x: hidden;
    }
  }

  .s7t-arrow {
    position: absolute;
    width: 30px;
    top: -10px;
    left: 45%;
    height: 30px;
    background: #ffffff;
    transform: rotate(45deg);
  }
}

.s7t-inputStyle {
  &:before {
    background-color: transparent !important;
  }

  div {
    &:before {
      background-color: transparent !important;
    }
  }
}

/*** --------------------- ***/

/** Radius Search For Enquiry **/
.s7t-radius-search-component {
  .s7t-available-icon {
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 3px;
    width: 20px;
    height: 20px;
  }

  .s7t-wait-icon {
    color: #fff;
    margin-right: 5px;
    margin-bottom: 3px;
    width: 20px;
    height: 20px;
  }

  .s7t-centre-list {
    height: 400px;
    overflow: auto;
    text-align: center;
    margin-top: 8px;
  }

  .s7t-selected-centre-card-placeholder {
    height: 132px;
    width: 234px;
    border-radius: 3px;
    margin-top: 8px;
    text-align: center;
    display: table;

    .s7t-card-placeholder-text {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .s7t-selected-centre-card {
    padding: 0px 12px 0px 12px;
    margin-top: 8px;
    height: 132px;
    width: 234px;

    .s7t-centre-name {
    }

    .s7t-centre-remove-btn {
      svg {
        width: 20px;
        height: 17px;
      }
    }

    .s7t-centre-address {
      font-size: 12px;
    }

    .s7t-centre-status {
      margin-top: 7px;
    }
  }
}

/*** --------------------- ***/

.s7t-place-autocomplete {
  padding: 20px 0px;

  #autocomplete_search {
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
    padding: 3px;
  }
}

.event-rsvp-active {
  background-color: lightgray;
}

.s7t-temperature-red {
  color: $redColor !important;
  font-size: 18px;
}

.s7t-button-style {
  justify-content: flex-end !important;
}

.s7t-autocomplete-search-result-li {
  &:hover {
    .white-hover {
      color: $whiteColor;
    }
  }
}

.s7t-horizontal-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.s7t-horizontal-line {
  margin-top: 40px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d5d5d5;
}

.s7t-dashed-horizontal-line {
  // border-bottom-width: 0.5px;
  border-color: #cfcfcf;
  border-style: none none dotted;
}

.s7t-left-padding-10 {
  padding-left: 10px;
}

.s7t-left-padding-20 {
  padding-left: 20px;
}

.s7t-left-margin-30 {
  margin-left: 20px;
}

.s7t-event-date-label {
  position: absolute;
  margin-top: -181px;
  background: white;
  padding: 10px;
  margin-left: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  // box-shadow: 1px 1px #ababab;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.s7t-event-date {
  margin-bottom: 5px;
  margin-top: 5px;
}

.s7t-event-card {
  // height: 425px;
}

.s7t-event-card-container1 {
  height: 180px;
}

.s7t-event-card-container2 {
  // height: 30px;
}

.s7t-margin-bottom-5 {
  margin-bottom: 5px;
}

.s7t-margin-vertical-10 {
  // margin-vertical: 10px;
  // margin-top: 10px;
  vertical-align: middle;
}

.s7t-event-custom-spacing {
  padding-top: 10px;
  padding-bottom: 10px;
}

.s7t-margin-top-15 {
  margin-bottom: 20px;
}

.s7t-margin-bottom-10 {
  margin-bottom: 10px;
}

.s7t-margin-top-10 {
  margin-top: 10px;
}

.s7t-preview-rsvp-yes-btn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.s7t-preview-rsvp-no-btn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.s7t-image-icon-bg {
  background-color: #f0f0f0;
  border-radius: 150px;
}

.s7t-event-preview-title {
  font-size: 24px;
}

.s7t-background-white {
  background-color: white;
}

.s7t-event-preview {
  background-color: #d8d8d8;
  border-radius: 36px;
  width: 406px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.s7t-preview {
  margin-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}

.s7t-event-preview-subtitle-text {
  margin-top: 10px;
}

.s7t-empty-text {
  color: 'gridBox';
}

.s7t-margin-top-30 {
  margin-top: 30px;
}

.s7t-margin-top-20 {
  margin-top: 20px;
}

.s7t-padding-40 {
  padding: 40px;
}

.s7t-black-color {
  color: #303030;
}

.s7t-multiselect-placeholder {
  position: relative;
  top: -25px;
}

.s7t-event-hint {
  color: #38576b;
  top: 15px;
  right: 10px;
  position: relative;
}

.s7t-browse-btn {
  border-width: 1px;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  align-self: center;
}

.s7t-terms-bg {
  background-color: #faf7f3;
  padding: 10px;
  border-radius: 8px;
}

.s7t-hint-info-icon {
  color: #ababab;
}

.s7t-default-hint-info-icon {
  color: $blueColor;
}

.s7t-bold-900 {
  font-weight: 900 !important;
}

.s7t-text-color-grey {
  color: #ababab;
}

.s7t-character-count-info {
  width: 12%;
  justify-content: flex-end;
  color: #ababab;
  font-style: italic;
}

.s7t-form-label {
  color: #ababab;
  margin-top: 30px;
  margin-bottom: 20px;
}

.s7t-upload-icon {
  color: #38576b;
  top: 5px;
  margin-left: 5px;
  position: relative;
}

.s7t-red {
  color: red;
}

.s7t-icons-text {
  color: #38576b;
}

.s7t-banner-alert {
  a {
    color: $whiteColor;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(192, 85, 242);
  color: $whiteColor;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 1205;

  .s7t-error-icon {
    height: 14px;
    width: 14px;
    margin-right: 4px;
  }

  .s7t-service-level {
    font-weight: 700;
    font-size: 14px;
    color: #303030;
  }

  .s7t-service-levels {
    font-weight: 400;
    font-style: normal;
    font-family: lato;
    font-size: 14px;
    color: #505050;
  }
}

.s7t-fab-icon {
  max-width: fit-content;
}

.s7t-guardian-details {
  font-weight: 700;
  font-size: 16px;
  color: $blackColor;
  word-break: break-word;
}

.s7t-text-grey {
  span {
    color: $greyColor !important;
  }

  color: $greyColor !important;
}

.position-relative {
  position: relative;
}

.s7t-flex-no-wrap {
  flex-wrap: nowrap;
}

.s7t-navy-color {
  color: #38576b;
}

.s7t-event-photo-btn {
  position: relative;
  top: -5px;
  padding-right: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.s7t-event-disabled-photo-btn {
  color: #e4e4e4;
}

.s7t-disabled-event-photo-btn {
  position: relative;
  top: -5px;
  padding-right: 10px;
  color: #e4e4e4;
}

.s7t-event-photo-dwnld-btn {
  align-content: center;
  padding-right: 40px;
}

.s7t-event-guest-bg {
  background-color: #f8f8f8;
}

.s7t-event-guest-del-btn {
  color: #38576b;
  position: relative;
  top: 5px;
}

.s7t-18-font-size {
  font-size: 18px;
  padding-left: 5px;
}

.s7t-event-class-text {
  position: relative;
  right: 50px;
}

.s7t-event-expand-card-border {
  border: 0.5px solid black;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.s7t-fontweight-400 {
  font-weight: 400;
}

.s7t-green-color {
  background-color: #12b886;
  color: white;
  font-size: 12px;
}

.s7t-event-detail-date-label {
  font-size: 16px;
  padding-right: 20px;
}

.s7t-text-label-grey {
  color: #a8a8a8;
}

.s7t-font-family-lato {
  font-family: Lato-Regular;
}

.s7t-text-color-6c6c6c {
  color: #6c6c6c;
}

.s7t-bg-navy-color {
  background-color: #38576b;
  color: white;
}

.s7t-text-align-end {
  text-align: end;
}

.s7t-rsvp-label-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.s7t-rsvp-label-value {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.s7t-event-expansion-card {
  box-shadow: none;
  border: 1px solid #e4e4e4;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
}

.s7t-padding-20 {
  padding: 20px;
}

.s7t-padding-vertical {
  padding-top: 20px;
  padding-bottom: 20px;
}

.s7t-dotted-divider {
  border: none;
  border-top: 1px dashed #e4e4e4;
  height: 1px;
  width: 50%;
  margin-top: 20px;
}

.s7t-rsvp-guest-label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6c6c6c;
}

.s7t-rsvp-guest-value {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.s7t-event-photo-totalcount {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 22px;
  color: #6c6c6c;
}

.s7t-class-chip-event {
  font-weight: 700;
  font-size: 14px;
  font-family: 'Lato-Regular';
  color: white;
}

.s7t-right-padding-30 {
  padding-right: 30px;
}

.s7t-right-padding-10 {
  padding-right: 10px;
}

.s7t-event-photo-btns {
  font-weight: 700;
  font-size: 16px;
}

.s7t-margin-left-80 {
  margin-left: -80px;
}

.s7t-rsvp-guest-mobile {
  margin-left: -30px;
}

.s7t-custom-chip {
  background-color: #38576b;
  max-width: 160px;
  border-radius: 40px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.s7t-icon-white-color {
  color: white;
}

.s7t-icon-cancel-text {
  font-size: 14px !important;
  word-break: break-all;
  font-weight: 500 !important;
  color: #c80000;
  margin-left: 30px;
}

.s7t-icon-save-text {
  font-size: 14px !important;
  word-break: break-all;
  font-weight: 500 !important;
  color: #38576b;
}

.s7t-show-hide-btn {
  margin-right: 20px;
}

.s7t-show-hide-drop-down {
  position: relative;
  top: -6px;
}

.s7t-class-hide-hint-head {
  font-weight: bold;
  font-size: 14px;
}

.s7t-class-hide-hint-sub-head {
  font-weight: 400;
  font-size: 14px;
}

.s7t-class-margin-left-40 {
  margin-left: 40px;
}

.s7t-class-title {
  font-weight: 700 !important;
  font-size: 18px !important;
}

.s7t-class-desc {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.s7t-class-hide-error-icon {
  color: '#C80000';
  justify-content: 'center';
  width: '100%';
  margin-top: 20px;
  margin-bottom: 20px;
}

.s7t-class-maintenance-font-h2 {
  font-family: 'Dk-sugary-pancake' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 45px !important;
  line-height: 48px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: $blackColor;
  margin-bottom: 10px;
}

.s7t-class-maintenance-font-family {
  font-family: 'Drawing-with-markers' !important;
  color: #f1be48;
}

.s7t-class-maintenance-font-h3 {
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 40px !important;
  line-height: 35px !important;
  color: $blackColor;
  margin-bottom: 20px;
}

.s7t-class-maintenance-font-400 {
  font-weight: 400 !important;
}

.s7t-class-maintenance-main {
  text-align: left !important;
  padding: 20px !important;
}

.s7t-class-maintenance-div {
  padding: 20px !important;
  display: grid !important;
}

.s7t-class-maintenance-container {
  width: 100% !important;
  height: 100vh !important;
  background: #ffffff !important;
}

.s7t-class-maintenance-logo-80 {
  width: 80px !important;
}

.s7t-class-maintenance-logo-100 {
  width: 100px !important;
}

.s7t-class-maintenance-logo-100p {
  width: 100% !important;
}

.s7t-class-maintenance-logo-300 {
  width: 300px !important;
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 200px !important;
  }
}

.s7t-class-maintenance-logo-400 {
  width: 400px !important;
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 250px !important;
  }
}

.s7t-class-maintenance-mfsbg-top-left {
  position: absolute !important;
  left: 0 !important;
  top: '15%';
}

.s7t-class-maintenance-mfsbg-top-right {
  position: absolute !important;
  right: 0 !important;
  top: 10%;
}

.s7t-class-maintenance-mfsbg-bottom-right {
  position: absolute !important;
  right: 0 !important;
  bottom: 10%;
}

.s7t-class-maintenance-mfsbg-bottom-left {
  position: absolute !important;
  left: 0 !important;
  bottom: 0 !important;
}

.s7t-class-maintenance-icon-bottom-10 {
  position: absolute !important;
  bottom: 10%;
}

.s7t-class-maintenance-lshbg-bottom-line {
  position: absolute !important;
  bottom: 0 !important;
}

.s7t-class-maintenance-lshbg-bottom-left {
  position: absolute;
  left: 0;
  bottom: 16px;
}

.s7t-class-maintenance-lshbg-bottom-right {
  position: absolute;
  right: 0;
  bottom: 16px;
}

.s7t-class-maintenance-lsh-mid-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
}

.s7t-pss-welcome-screen-container {
  background-color: white;
  padding: 50px;
  border-radius: 20px;
  margin-bottom: 50px;
}

.s7t-pss-welcome-screen-container-mobile {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 50px;
}

.s7t-pss-primary-btn {
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.s7t-pss-padding {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.s7t-pss-welcome-page-title {
  font-weight: 700 !important;
  font-size: 32px !important;
}

.s7t-pss-welcome-page-title-mobile {
  font-weight: 700 !important;
  font-size: 24px !important;
}

.s7t-pss-invite-subtext {
  font-weight: 400 !important;
  font-size: 16px;
}

.s7t-padding-bottom-30 {
  padding-bottom: 30px;
}

.s7t-padding-bottom-20 {
  padding-bottom: 20px;
}

.s7t-pss-success-screen {
  background-color: 'white';
  justify-content: 'center';
  display: 'flex';
}

.s7t-vertical-margin-35 {
  margin-bottom: 35px;
  margin-top: 35px;
}

.s7t-pss-success-tick {
  color: '#12B886';
  height: 66px;
  width: 66px;
}

.s7t-pss-success-txt-1 {
  font-weight: 700 !important;
  font-size: 24px !important;
}

.s7t-pss-success-txt-2 {
  font-weight: 400 !important;
  font-size: 18px !important;
}

.s7t-pss-qstn-header {
  font-weight: 600 !important;
  font-size: 32px !important;
  font-family: 'Lato-Regular';
  overflow-wrap: break-word;
  margin-bottom: 16px;
}

.s7t-pss-header {
  position: relative !important;
  top: 16px !important;
  right: 0px !important;
}

.s7t-pss-header-mobile {
  position: relative !important;
  top: 12px !important;
  right: 0px !important;
}

.s7t-pss-portal-header {
  font-weight: 700 !important;
  font-size: 32px !important;
}

.s7t-pss-portal-header2 {
  font-weight: 400 !important;
  font-size: 32px !important;
  margin-bottom: 48px;
}

.s7t-pss-portal-text {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.s7t-pss-portal-margin-bottom {
  margin-bottom: 30px;
}

.s7t-pss-portal-date-text {
  font-weight: 700 !important;
  font-size: 18px !important;
}

.s7t-pss-primary-btn1 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.s7t-pss-child-name {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.s7t-pss-survey-time {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: '#6C6C6C' !important;
}

.sn2-d-justify-content-end {
  justify-content: end;
}

.sn2-center-items {
  align-items: center;
}

.sn2-reason-text {
  color: #a8a8a8;
  font-size: 14px;
}

.sn2-margin-bottom-15 {
  margin-bottom: 15px;
}

.sn2-padding-left-25 {
  padding-left: 25px;
}

.margin-bottom-64 {
  margin-bottom: 64px;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}
.s7t-parent-portal-child-card {
  box-shadow: none;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
}
